.episodes {
    padding-top: 65px;
    padding-bottom: 50px;
    background-color: $proartGrayColor;
    color: $proartMainFontColor;

    .content-wrapper {
        padding-left: 2rem;
        padding-right: 2rem;

        @include rwd(laptop) {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    &.bg-primary {
        background-color: $proartMainBackgroundColor;
        color: $whiteColor;

        .swiper-button-next,
        .swiper-button-prev {
            color: $proartGrayColor;
        }
    }
}

.episodes__header {
    margin: 0;
    padding-bottom: 65px;
}

.episodes__title {
    margin: 0;
}

.episodes__swiper {
    padding-bottom: 30px;

    &:not(.swiper-initialized) {
        .swiper-wrapper {
            display: flex;
            gap: 45px;
        }

        .episodes-swiper__item {
            width: 25%;
            flex-shrink: 1;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        transform: translateY(-50%);
        width: 44px;

        &::after {
            width: 44px;
        }

        &.swiper-button-disabled {
            pointer-events: all;
        }
    }

    .swiper-button-next::after {
        content: url('/data/icons/left.svg');
    }

    .swiper-button-prev::after {
        content: url('/data/icons/right.svg');
    }

    .swiper-scrollbar.swiper-scrollbar-horizontal {
        left: 0;
        right: 0;
        width: 100%;
        height: 12px;
    }
}

.episodes-swiper__item {
    display: block;
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.episodes-swiper__item-figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 75%;
    overflow: hidden;
    display: block;

    .skeleton,
    img:not(.episodes-swiper__series-thumbnail) {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .episodes-swiper__series-thumbnail {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}

.episodes-swiper__item-header {
    margin-bottom: 10px;
}

.episodes-swiper__item-title {
    margin-bottom: 0;
    margin-top: 20px;
    text-align: start;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.episodes-swiper__item-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    line-height: 1.1rem;
    max-height: 4.4rem;
    margin-bottom: 15px;
}

.episodes-skeleton {
    .episodes-skeleton__title {
        margin-top: 20px;
    }
}

.episodes-group {
    scroll-margin-top: 90px;

    &.odd {
        .episodes-group__container {
            @include rwd(laptop) {
                flex-direction: row;
            }
        }
    }

    &.lightorange {
        background-color: $proartOrangeColor;
    }

    &.primary {
        background-color: $proartMainBackgroundColor;
    }

    &.orange {
        background-color: $proartSecondColor;
    }

    &.green {
        background-color: $proartGreenColor;
    }
}

.episodes-group__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;

    @include rwd(laptop) {
        flex-direction: row-reverse;
    }
}

.episodes-group__picture-col {
    width: 100%;
    aspect-ratio: 16 / 9;

    @include rwd(laptop) {
        width: 50%;
    }

    @include rwd(desktop) {
        width: 60%;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 16 / 9;
        display: block;
    }
}

.episodes-group__content-col {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include rwd(laptop) {
        padding: 20px 50px;
        width: 50%;
    }

    @include rwd(desktop) {
        width: 40%;
    }
}

.episodes-group__content {
    text-align: start;
    color: $whiteColor;
}

.episodes-group__title {
    line-height: normal;
    font-weight: 900;
    text-align: inherit;
    margin-bottom: 30px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: $fontSizeXXL;

    @include rwd(tablet) {
        font-size: $fontSizeXXXL;
    }

    @include rwd(laptop) {
        font-size: calc($fontSizeXXXXL - 18px);
        margin-bottom: 20px;
    }
}

.episodes-group__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;

    @include rwd(tablet) {
        -webkit-line-clamp: 3;
        line-clamp: 3;
    }

    @include rwd(laptop) {
        -webkit-line-clamp: 10;
        line-clamp: 10;
    }
}

.episodes-group__cta {
    margin-top: auto;
    text-align: start;

    @include rwd(laptop) {
        text-align: center;
    }

    .button {
        &:after {
            content: '>>';
            margin-left: 0.5rem;
            display: inline-block;
        }
    }
}

.episodes__promoted {
    background-color: $blackColor;
    position: relative;
    min-height: 45vh;
    overflow: hidden;
    width: 100%;

    @include rwd(tablet) {
        min-height: 400px;
        aspect-ratio: 16 / 9;
    }

    @include rwd(laptop) {
        height: auto;
        min-height: auto;
    }

    &-header {
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding-right: 20px;
        z-index: 3;
        max-width: calc(100% - 20px);

        @include rwd(tablet) {
            bottom: auto;
            top: 50%;
            padding-left: 20px;
            transform: translateY(-20%);
        }

        @include rwd(laptop) {
            padding-left: 80px;
            transform: translateY(-30%);
        }
    }

    &-title {
        @extend .episodes-group__title;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-shadow: 0px 0px 2px $blackColor;
        color: $whiteColor;
        margin: 0;
        line-height: 32px;

        @include rwd(tablet) {
            line-height: 58px;
        }

        @include rwd(laptop) {
            line-height: 62px;
            margin-bottom: 16px;
        }
    }

    &-date {
        color: $proartSecondColor;
        text-shadow: 0px 0px 2px $blackColor;
        font-size: $fontSizeL;
        font-weight: bold;

        @include rwd(tablet) {
            font-size: $fontSizeXL;
        }

        @include rwd(laptop) {
            font-size: $fontSizeXXL;
        }
    }

    &-description {
        @extend .episodes-group__description;
        color: $whiteColor;
        width: 100%;
        margin-top: 10px;
        text-shadow: 0px 0px 2px $blackColor;

        @include rwd(tablet) {
            width: 42%;
            margin-top: 12px;
            min-width: 320px;
        }

        @include rwd(laptop) {
            min-width: 400px;
        }
    }

    &-live {
        margin-top: 20px;

        svg {
            width: 144px;
            height: auto;

            .cls-1 {
                fill: $whiteColor;
            }

            .cls-2 {
                fill: #e52521;
            }

            .cls-3 {
                fill: #f39323;
            }
        }
    }

    &-button {
        display: block;
        margin-top: 10px;

        svg {
            width: 144px;
            height: auto;

            .cls-1 {
                fill: $whiteColor;
            }

            .cls-2 {
                fill: #e52521;
            }

            .cls-3 {
                fill: #4b5b73;
            }
        }

        @include rwd(tablet) {
            margin-top: 24px;
        }

        @include rwd(laptop) {
            margin-top: 36px;
        }
    }

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($blackColor, 0.35);
        z-index: 2;
    }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        background-color: $blackColor;
        min-width: 100%;
        object-fit: cover;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
