.develop-your-business-with-us {
    h2.section-header {
        color: $fontColor;
        background-color: $backgroundColorSolidSectionHeader;
    }

    .block-container {
        display: grid;
        grid-template-columns: 1fr;

        @include rwd(laptop) {
            grid-template-columns: 1fr 1fr;
        }

        .block {
            padding: 30px 20px;

            @include rwd(laptop) {
                display: flex;
                flex-direction: column;
            }

            @include rwd(desktop) {
                padding: 40px;
            }

            &.gray {
                background-color: $proartGrayColor;
            }

            &.blue {
                background-color: $proartMainBackgroundColor;
            }

            &.orange {
                background-color: $proartSecondColor;

                a {
                    background-color: $proartMainBackgroundColor;
                    color: $whiteColor;
                }
            }

            &.white {
                background-color: $backgroundColorSolidAlternate;
            }

            &.blue,
            &.gray,
            &.white {
                a {
                    background-color: $proartSecondColor;
                    color: $whiteColor;
                }
            }

            &:nth-child(1),
            &:nth-child(2) {
                color: $whiteColor;
            }

            h3 {
                font-weight: 900;
                letter-spacing: 0;
                text-align: left;
                font-size: $fontSizeXXL;
                line-height: $fontSizeXXL;
                text-align: center;

                @include rwd(tablet) {
                    font-size: $fontSizeSubtitle;
                    line-height: $fontSizeSubtitle;
                }

                @include rwd(laptop) {
                    width: 10px;
                    text-align: left;
                }
            }

            .content {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: center;
                text-align: justify;

                @include rwd(desktop) {
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 0;
                }

                img {
                    width: 100%;

                    @include rwd(tablet) {
                        width: auto;
                        max-width: 400px;
                    }

                    @include rwd(laptop) {
                        max-width: 100%;
                    }

                    @include rwd(desktop) {
                        width: 47%;
                    }
                }

                p {
                    font-weight: 300;
                    font-size: $fontSizeM;
                    line-height: $fontSizeL;
                    margin: 0;
                    text-align: left;

                    @include rwd(tablet) {
                        max-width: 400px;
                    }

                    @include rwd(laptop) {
                        font-size: $fontSizeL;
                        line-height: $fontSizeXL;
                        max-width: none;
                    }

                    @include rwd(desktop) {
                        width: 47%;
                    }
                }
            }

            .button-container {
                margin: 30px 0 0;
                display: flex;
                justify-content: center;

                @include rwd(laptop) {
                    align-self: center;
                    height: 100%;
                    align-items: flex-end;
                }

                a {
                    @include rwd(tablet) {
                        padding: 0.7rem 3rem;
                        text-transform: uppercase;
                        font-size: $fontSizeL;
                    }
                }
            }
        }
    }
}
