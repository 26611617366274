.next-prev-video {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > * {
        width: 100%;
    }

    @include rwd(laptop) {
        gap: 100px;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;

        & > * {
            width: calc(50% - 50px);
        }
    }
}

.next-prev-video__anchor {
    color: $proartMainFontColor;
    display: inline-block;
    max-width: 100%;

    &:hover {
        text-decoration: none;
        color: $proartSecondColor;
    }

    & > * {
        display: block;
        width: 100%;
    }
}

.next-prev-video__next {
    @include rwd(laptop) {
        text-align: end;
        margin-left: auto;
    }
}

.next-prev-video__show-episodes {
    color: $proartMainFontColor;

    @include rwd(laptop) {
        text-align: center;
        margin-top: auto;
    }

    &:hover {
        text-decoration: none;
        color: $proartSecondColor;
    }
}

.next-prev-video__prefix {
    font-size: $fontSizeM;
}

.next-prev-video__title {
    font-size: $fontSizeL;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include rwd(laptop) {
        font-size: $fontSizeXL;
    }
}
