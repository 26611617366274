.proart-in-numbers {
    .container {
        margin: 20px auto;
        justify-content: space-evenly;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 40px;

        @include rwd(laptop) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }

        .block {
            aspect-ratio: 4/3;
            max-width: 400px;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            @include rwd(tablet) {
                flex: 1;
                max-width: 500px;
                min-width: 400px;
            }

            @include rwd(desktop) {
                padding: 50px;
                max-width: 750px;
            }

            h3 {
                span {
                    display: block;

                    &.title {
                        font-weight: 900;
                        letter-spacing: 0;
                        font-size: $fontSizeSubtitle;
                        line-height: $fontSizeSubtitle;

                        @include rwd(tablet) {
                            font-size: $fontSizeSubtitlePlus;
                            line-height: $fontSizeSubtitlePlus;
                        }

                        @include rwd(laptop) {
                            font-size: $fontSizeXXXXL;
                            line-height: $fontSizeXXXXL;
                        }

                        @include rwd(desktop) {
                            font-size: $fontSizeXXXXLDesktop;
                            line-height: $fontSizeXXXXLDesktop;
                        }
                    }

                    &.subtitle {
                        font-weight: 800;
                        font-size: $fontSizeXL;

                        @include rwd(tablet) {
                            font-size: $fontSizeXXL;
                            line-height: 1.6rem;
                        }

                        @include rwd(laptop) {
                            font-size: $fontSizeXXXL;
                        }
                        @include rwd(desktop) {
                            line-height: $fontSizeSubtitle;
                        }
                    }
                }
            }

            p {
                text-align: left;
                font-size: $fontSizeM;
                line-height: $fontSizeL;
                margin: 0;

                @include rwd(tablet) {
                    font-size: $fontSizeL;
                    line-height: $fontSizeXL;
                }

                @include rwd(laptop) {
                    font-size: $fontSizeXL;
                    line-height: $fontSizeXXL;
                }
            }

            &:first-of-type,
            &:last-of-type {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    z-index: -1;
                }
            }

            &:first-of-type {
                color: $proartMainFontColor;

                &::before {
                    background-image: url('/data/home/telewizor-tlo.png');
                }
            }

            &:last-of-type {
                color: $proartMainFontColor;

                &::before {
                    background-image: url('/data/home/ludzie-tlo.png');
                }
            }
        }
    }
}

body.dark-theme {
    .proart-in-numbers {
        .block {
            &:first-of-type,
            &:last-of-type {
                &::before {
                    filter: invert(1) brightness(5);
                }
            }
        }
    }
}
