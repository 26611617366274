.footer {
    background-color: $proartMainBackgroundColor;
    padding-top: 55px;
    padding-bottom: 55px;
    color: $whiteColor;
    font-weight: 300;

    a {
        font-weight: inherit;
        color: $whiteColor;
        text-decoration: none;

        &:hover {
            color: inherit;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding-left: 1rem;
        padding-right: 1rem;
        flex-wrap: wrap;
        gap: 1rem;

        @include rwd(laptop) {
            width: 900px;
        }

        @include rwd(desktop) {
            width: 1280px;
        }
    }

    &-title {
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 2rem;

        &.content {
            margin: 0;
        }

        &.uppercase {
            text-transform: uppercase;
        }
    }

    &-menu__box {
        flex-shrink: 0;
    }

    &-menu {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &-address__column {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        @include rwd(desktop) {
            gap: 3rem;
        }
    }

    &-address {
        font-style: normal;
    }

    .skeleton-pharagraph {
        width: 180px;
        height: 20px;
        margin-bottom: 6px;
    }

    &-social__box {
        flex-shrink: 0;
    }

    &-social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1.1rem;
    }

    &-social__link {
        width: 40px;
        height: 40px;

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &-author {
        margin: 50px auto 0 auto;
        font-size: $fontSizeS;
    }

    .contact-message-popup-button {
        border: 0;
        background-color: transparent;
        color: $proartOrangeColor;
        box-shadow: none;
        font-size: $fontSizeM;
        font-weight: 300;
        cursor: pointer;
        font-family: $fontFamily;

        @include rwd(laptop) {
            font-size: $fontSizeL;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

a.footer-logo__download {
    display: flex;
    flex-direction: column;
    color: $proartOrangeColor;
    line-height: 2rem;

    img {
        height: 30px;
        width: auto;
    }
}
