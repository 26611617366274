.contact-message-popup {
    display: none;

    @keyframes checkAnimation {
        0% {
            height: 0;
        }

        100% {
            height: 10px;
        }
    }

    &.active {
        display: flex;

        .form-container {
            box-shadow: 0 0 10px 0 rgba($blackColor, 0.5);
            border-radius: 12px;
            padding: 20px;
            max-width: 600px;
            width: 100%;
            background-color: $backgroundColor;
            max-height: 92vh;
            overflow-y: auto;
            position: relative;

            .input-container {
                label,
                input,
                textarea {
                    background-color: $backgroundColor;
                }
            }
        }
    }
}
