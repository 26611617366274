.comments-rss-form {
    padding: 120px 20px 20px;
    max-width: 640px;
    margin: 0 auto;

    .uploader-container {
        margin-bottom: 10px;

        label {
            display: inline-block;
            margin-bottom: 2px;
        }
    }
}
