.team {
    background-color: $whiteColor;
}

h2.team-title {
    background-color: $backgroundColorSolidSectionHeader;
    color: $proartMainFontColor;
}

.team-picture {
    background-color: $proartMainBackgroundColor;
    padding-bottom: 5px;

    img {
        display: block;
        width: 100%;
    }
}

.team-content {
    color: $whiteColor;
    background-color: $proartMainBackgroundColor;
    padding: 25px 0 30px;
    margin-top: -1px;
}

.team-cta {
    text-align: center;
    margin: 0 1rem;
}
