.contact-data {
    &__grid.content-wrapper {
        display: grid;
        grid-gap: 50px;
        padding-top: 40px;
        padding-bottom: 40px;
        grid-template-columns: 100%;

        @include rwd(laptop) {
            grid-template-columns: calc(50% - 50px) 50%;
        }
    }

    &__info {
        .footer-contact {
            margin-top: 24px;

            &-title {
                font-size: $fontSizeXXXL;
                line-height: $fontSizeXXXL;
                font-weight: 700;
                text-transform: uppercase;
                color: $proartMainFontColor;
                margin-bottom: 40px;

                @include rwd(laptop) {
                    margin-bottom: 70px;
                }
            }
        }

        .footer-email {
            margin-top: 24px;

            .contact-message-popup-button {
                border: 0;
                background-color: transparent;
                color: $fontColor;
                box-shadow: none;
                font-size: $fontSizeM;
                cursor: pointer;
                font-family: $fontFamily;

                @include rwd(laptop) {
                    font-size: $fontSizeL;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__map {
        iframe {
            border: 1px solid $proartGrayColor;
        }
    }
}
