.big-name-header {
    font-weight: 900;
    letter-spacing: 0;
    text-align: left;
    font-size: $fontSizeXXL;
    line-height: $fontSizeXXL;
    text-transform: uppercase;

    @include rwd(tablet) {
        font-size: $fontSizeSubtitle;
        line-height: $fontSizeSubtitle;
    }

    @include rwd(laptop) {
        font-size: $fontSizeSubtitlePlus;
        line-height: $fontSizeSubtitlePlus;
        width: 10px;
    }
}

.section-header {
    font-size: $fontSizeL;
    text-align: center;
    padding: 20px;
    font-weight: 600;
    color: $whiteColor;
    background-color: $proartMainBackgroundColor;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin: 0;

    @include rwd(tablet) {
        padding: 30px 20px;
        font-size: $fontSizeXL;
        line-height: $fontSizeXL;
    }

    @include rwd(laptop) {
        padding: 50px 20px;
        font-size: $fontSizeXXL;
        line-height: $fontSizeXXL;
    }
}
