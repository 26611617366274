.form-container {
    $checkboxColor: #2196f3;
    $checkboxColorShadow: rgba(33, 150, 243, 0.3);

    .close-button {
        background-color: transparent;
        color: $fontColor;
        box-shadow: none;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        font-size: 1.5rem;
        color: $fontColor;
        border: none;
        outline: none;
    }

    textarea {
        height: 120px;
        resize: none;
    }

    .star {
        &::after {
            content: ' *';
            color: $proartSecondColor;
        }
    }

    p {
        font-size: $fontSizeS;
    }

    a {
        color: $fontColor;
    }

    .button-container {
        margin: 0 auto;
        width: fit-content;

        .button {
            padding: 1rem 3rem;
        }
    }

    .checkbox-container {
        display: inline-block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: $fontSizeS;
        user-select: none;

        .custom-checkbox {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                background-color: $checkboxColor;
                box-shadow: 0 3px 7px $checkboxColorShadow;
            }

            &:checked ~ .checkmark:after {
                display: block;
                animation: checkAnim 0.2s forwards;
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $whiteColor;
            border-radius: 4px;
            transition: background-color 0.3s;
            box-shadow: 0 2px 5px rgba($blackColor, 0.2);

            &:after {
                content: '';
                position: absolute;
                display: none;
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid $whiteColor;
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }
    }

    .input-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        position: static;
        width: 100%;
        margin-bottom: 12px;

        label {
            font-size: $fontSizeS;
            color: $fontColor;
            font-weight: 300;
            position: relative;
            top: 0.5rem;
            margin: 0 0 0 7px;
            padding: 0 3px;
            background: $backgroundColorSolid;
            width: fit-content;
            font-family: $fontFamily;
        }

        input[type='text'],
        textarea {
            font-size: $fontSizeM;
            padding: 11px 10px;
            border: 1px $grayColor solid;
            border-radius: 5px;
            background: $backgroundColorSolid;
            font-family: $fontFamily;
            color: $fontColor;
            -webkit-text-fill-color: $fontColor;

            &::placeholder {
                color: $grayColor;
            }

            &:-internal-autofill-selected {
                background-color: none !important;
                color: $fontColor !important;
            }

            &:focus {
                outline: none;
                border: 1px $fontColor solid;
            }
        }
    }
}
