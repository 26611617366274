.material-video-container {
    $navbarHeight: 100px;

    padding: calc($navbarHeight + 20px) 20px 20px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 100%;

    @include rwd(laptop) {
        grid-template-columns: calc(72% - 30px) 28%;
    }
}
