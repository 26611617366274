.about-us__section {
    overflow: hidden;

    &:nth-of-type(even) {
        .section-header {
            background-color: $proartSecondColor;
        }
    }

    .content-wrapper {
        padding: 20px 20px 0;
    }

    .about-us__section-grid:not(.single) {
        &:first-of-type,
        &:nth-of-type(3n + 1) {
            background-color: $proartGrayColor;
        }

        &:nth-of-type(3n + 2) {
            background-color: $backgroundColorSolidAlternate;

            @include rwd(tablet) {
                .about-us__item-picture {
                    order: -1;
                }
            }
        }

        &:nth-of-type(3n) {
            background-color: $proartSecondColor;
            color: $whiteColor;

            .about-us__item-caption {
                a.button.orange {
                    background-color: $proartMainBackgroundColor;
                    color: $whiteColor;

                    &:hover {
                        background-color: $proartMainBackgroundColor;
                    }
                }
            }
        }
    }
}

.about-us__section-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 100%;
    grid-gap: 50px;
    color: $proartMainFontColor;

    &.single {
        .about-us__item-caption {
            h2 {
                br {
                    display: none;
                }
            }
        }
    }

    @include rwd(laptop) {
        grid-template-columns: calc(50% - 50px) calc(50% - 50px);
        grid-gap: 80px;

        &.single {
            grid-template-columns: 65%;
            grid-gap: 0px;

            .about-us__section-grid-item {
                grid-template-columns: 60% 40%;
                gap: 1rem;
            }

            .about-us__item-caption {
                margin-top: 80px;
                height: calc(100% - 80px);
            }
        }
    }
}

.about-us__section-grid-item {
    display: grid;
    grid-template-columns: 100%;

    @include rwd(tablet) {
        grid-template-columns: 50% 50%;
        gap: 1rem;
    }
}

.about-us__item {
    &-caption {
        padding-bottom: 20px;
        margin-bottom: 20px;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;

        h2 {
            text-transform: uppercase;
            margin-bottom: 30px;
            font-size: $fontSizeXL;
            line-height: $fontSizeXL;

            @include rwd(tablet) {
                text-align: start;
                font-size: $fontSizeXXL;
                line-height: $fontSizeXXL;
            }

            @include rwd(laptop) {
                font-size: $fontSizeXXXL;
                line-height: $fontSizeXXXL;
            }
        }

        div {
            text-align: justify;
            font-weight: 300;
            font-size: $fontSizeM;
            line-height: $fontSizeM;

            @include rwd(laptop) {
                font-size: $fontSizeL;
                line-height: $fontSizeXL;
            }

            &.about-us__item-description {
                margin-bottom: 8px;
                text-align: left;
            }
        }
    }

    &-button {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        gap: 10px;

        @include rwd(laptop) {
            order: unset;
        }

        .button {
            font-size: $fontSizeM;
            text-align: left;
            padding: 0.5rem 1rem;
            text-transform: none;
            width: 100%;

            @include rwd(tablet) {
                width: fit-content;
            }
        }
    }
}

.about-us__item-picture {
    order: -1;

    @include rwd(laptop) {
        order: unset;
    }

    img {
        max-width: 290px;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
        object-position: bottom;
        width: 100%;
        height: 100%;
        display: block;

        @include rwd(tablet) {
            max-width: unset;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
