.button {
    border: unset;
    color: $whiteColor;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    padding: 1rem 0;
    width: 100%;
    max-width: 430px;
    border-radius: 3rem;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;

    &[disabled] {
        opacity: 0.5;
        color: $grayColor;
        border: 2.5px solid $grayColor;
        pointer-events: none;
    }

    &:hover {
        text-decoration: none;
        color: $whiteColor;

        &:before {
            width: 100%;
        }
    }

    &.search {
        width: auto;
        font-weight: 300;
        text-transform: none;
        padding: 0;
        border-radius: 0;
    }

    &.orange {
        background-color: $proartSecondColor;
        color: $whiteColor;

        &:hover {
            background-color: $proartOrangeColor;
        }
    }

    &.primary {
        background-color: $proartMainBackgroundColor;
        color: $whiteColor;

        &:after {
            content: '>>';
            margin-left: 0.5rem;
            display: inline-block;
        }

        &:hover {
            background-color: $proartMainBackgroundColor;
        }
    }

    &.orange-link {
        width: auto;
        font-weight: 300;
        text-transform: none;
        padding: 0;
        border-radius: 0;
        color: $proartSecondColor;
        font-weight: 500;
    }

    &.white {
        background-color: $backgroundColorSolid;
        color: $proartMainFontColor;

        &:hover {
            color: $proartMainFontColor;
        }
    }

    &.xl {
        font-size: $fontSizeM;

        @include rwd(tablet) {
            font-size: $fontSizeL;
        }

        @include rwd(desktop) {
            font-size: $fontSizeXL;
        }

        @include rwd(tv) {
            font-size: $fontSizeXXL;
        }
    }
}
