.about-us__main-section {
    &-img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
        max-height: 500px;
    }

    &-content {
        max-width: 1280px;
        margin: 0 auto 20px;
        padding: 20px 20px 0 20px;
        text-align: justify;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        min-width: 0;
        width: 100%;
    }

    &-button-container {
        display: flex;
        justify-content: center;
        margin: 0 auto 20px;
        padding: 0 20px;
    }
}
