.banner-offer-wrapper {
    position: relative;
    aspect-ratio: 2.286 / 1;
    height: 420px;
    width: 100%;
    background-color: $proartMainBackgroundColor;

    @include rwd(tablet) {
        height: auto;
        min-height: 500px;
    }

    @include rwd(laptop) {
        min-height: auto;
    }

    .content-wrapper {
        position: absolute;

        h1 {
            text-align: left;
            color: $whiteColor;
            margin: 0;
            letter-spacing: 2px;
            padding: 120px 10px 10px;
            text-shadow: 1px 1px 2px rgba($blackColor, 1);

            @include rwd(tablet) {
                padding: 160px 20px 10px;
            }

            @include rwd(laptop) {
                text-shadow: none;
                padding: 180px 50px 10px;
            }

            @include rwd(desktop) {
                text-shadow: none;
                padding: 260px 100px 10px;
            }

            span {
                display: block;

                &.title {
                    text-transform: uppercase;
                    font-size: $fontSizeXXXL;
                    line-height: $fontSizeXXXL;
                    font-weight: 700;

                    @include rwd(tablet) {
                        font-size: $fontSizeSubtitlePlus;
                        line-height: $fontSizeSubtitlePlus;
                    }

                    @include rwd(laptop) {
                        font-weight: 900;
                        font-size: $fontSizeXXXXL;
                        line-height: $fontSizeXXXXL;
                    }
                }

                &.subtitle {
                    font-size: $fontSizeXXL;
                    line-height: $fontSizeXXL;
                    font-weight: 500;
                    margin-bottom: 5px;

                    @include rwd(tablet) {
                        font-size: $fontSizeSubtitle;
                        line-height: $fontSizeSubtitle;
                    }

                    @include rwd(laptop) {
                        margin-bottom: 18px;
                        font-size: $fontSizeSubtitlePlus;
                        line-height: $fontSizeSubtitlePlus;
                    }
                }
            }
        }
    }

    img,
    .skeleton {
        display: block !important;
        width: 100%;
        height: 420px;
        object-position: -280px;
        object-fit: cover;

        @include rwd(tablet) {
            object-position: inherit;
            height: auto;
            min-height: 500px;
        }

        @include rwd(laptop) {
            object-position: center;
            min-height: auto;
        }
    }

    .skeleton {
        aspect-ratio: 2.286 / 1;
    }
}
