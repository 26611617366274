$iconColor: #4b5b73;

svg {
    width: $iconSizeSocial;
    height: $iconSizeSocial;
    cursor: pointer;

    &.facebook {
        path {
            fill: $iconColor;
        }

        &:hover {
            path {
                fill: $whiteColor;
            }
        }
    }

    &.instagram {
        path {
            fill: $iconColor;
        }

        &:hover {
            path {
                fill: $whiteColor;
            }
        }
    }

    &.youtube {
        path,
        polygon {
            fill: $iconColor;
        }

        &:hover {
            path,
            polygon {
                fill: $whiteColor;
            }
        }
    }

    &.loupe {
        fill: $whiteColor;
    }
}
