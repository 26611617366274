.program-tv-slider {
    $textShadow: 1px 1px 2px rgba($blackColor, 1);
    min-height: 80px;
    background-color: $proartMainBackgroundColor;

    @include rwd(laptop) {
        min-height: 100px;
    }

    .swiper {
        .swiper-slide {
            position: relative;

            img {
                display: block;
                width: 100%;
                height: 420px;
                object-position: center;
                object-fit: cover;
                filter: brightness(0.65);

                @include rwd(tablet) {
                    height: 500px;
                }

                @include rwd(laptop) {
                    height: auto;
                    aspect-ratio: 16 / 9;
                    min-height: 700px;
                }
            }

            .content {
                position: absolute;
                top: 0;
                color: $whiteColor;
                padding: 120px 10px 10px;

                @include rwd(tablet) {
                    padding: 150px 20px 10px;
                }

                @include rwd(laptop) {
                    text-shadow: none;
                    padding: 180px 50px 10px;
                }

                @include rwd(desktop) {
                    text-shadow: none;
                    padding: 220px 100px 10px;
                }

                h2 {
                    text-align: left;
                    margin-bottom: 12px;
                    letter-spacing: 2px;
                    text-shadow: $textShadow;

                    span {
                        display: block;

                        &.title {
                            text-transform: uppercase;
                            font-size: $fontSizeXXXL;
                            line-height: $fontSizeXXXL;
                            font-weight: 700;

                            @include rwd(tablet) {
                                font-size: $fontSizeSubtitlePlus;
                                line-height: $fontSizeSubtitlePlus;
                            }

                            @include rwd(laptop) {
                                font-weight: 900;
                                font-size: $fontSizeXXXXL;
                                line-height: $fontSizeXXXXL;
                            }
                        }

                        &.subtitle {
                            font-size: $fontSizeXXL;
                            line-height: $fontSizeXXL;
                            font-weight: 500;
                            margin-bottom: 2px;

                            @include rwd(tablet) {
                                font-size: $fontSizeSubtitle;
                                line-height: $fontSizeSubtitle;
                            }

                            @include rwd(laptop) {
                                font-size: $fontSizeSubtitlePlus;
                                line-height: $fontSizeSubtitlePlus;
                            }
                        }
                    }
                }

                h3 {
                    text-align: left;
                    font-size: $fontSizeXL;
                    margin: 0;
                    text-shadow: $textShadow;
                }

                .premier {
                    font-size: $fontSizeM;
                    text-transform: uppercase;
                    background-color: $successColor;
                    padding: 1px 10px;
                    width: fit-content;
                    border-radius: 10px;
                    margin: 12px 0;
                }

                .description {
                    text-shadow: $textShadow;
                    margin: 0;
                    max-width: 720px;
                }
            }
        }
    }
}
