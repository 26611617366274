.text-main {
    color: $mainColor;
}

.text-error {
    color: $errorColor;
}

.text-warning {
    color: $warningColor;
}

.text-success {
    color: $successColor;
}
