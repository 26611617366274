@use 'sass:math';

.notification-container {
    $animationDuration: 0.6s;
    $horizontalPadding: 20px;
    $padding: 20px $horizontalPadding;
    $gap: 10px;
    $maxHeight: 100px;
    $animationHalfDuration: math.div($animationDuration, 2);

    @keyframes fade {
        0% {
            opacity: 0;
            max-height: 0;
            margin-top: 0;
            padding: 0 $horizontalPadding;
        }

        50% {
            opacity: 0;
            max-height: $maxHeight;
            margin-top: $gap;
            padding: $padding;
        }

        100% {
            opacity: 1;
        }
    }

    position: fixed;
    right: 0;
    bottom: 50px;
    padding: 0 $gap;
    width: 100%;
    z-index: 101;

    @include rwd(tablet) {
        bottom: 10px;
        width: 500px;
    }

    &.centered {
        right: 50%;
        bottom: 50%;
        transform: translate(50%, -50%);
    }

    .notification {
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $gap;
        padding: $padding;
        gap: 10px;
        max-height: $maxHeight;
        opacity: 1;
        animation: fade $animationDuration linear;
        overflow: hidden;
        transition:
            opacity $animationHalfDuration linear,
            max-height $animationHalfDuration linear $animationHalfDuration,
            margin-top $animationHalfDuration linear $animationHalfDuration,
            padding $animationHalfDuration linear $animationHalfDuration;

        &-message {
            flex-basis: 86%;
            font-size: $fontSizeM;
        }

        &-icon {
            flex-basis: 7%;
            display: flex;
            align-items: center;
        }

        &-close {
            flex-basis: 7%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
        }

        &.success {
            background-color: $successColorBackground;
            color: $successColor;
            border: 1px solid $successColor;
        }

        &.warning {
            background-color: $warningColorBackground;
            color: $warningColor;
            border: 1px solid $warningColor;
        }

        &.error {
            background-color: $errorColorBackground;
            color: $errorColor;
            border: 1px solid $errorColor;
        }

        &.info {
            background-color: $mainColorBackground;
            color: $mainColor;
            border: 1px solid $mainColor;
        }

        &.dismissed {
            max-height: 0;
            opacity: 0;
            margin-top: 0;
            padding: 0 $horizontalPadding;
            border: 0;
            pointer-events: none;
        }
    }
}
