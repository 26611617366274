.material-video {
    width: 100%;

    .video-container {
        aspect-ratio: 16 / 9;
        border-radius: 20px;
        overflow: hidden;
        position: relative;

        &:hover {
            .fullscreen-btn {
                opacity: 1;
            }
        }

        .fullscreen-btn {
            position: absolute;
            top: 12%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-decoration: none;
            transition: opacity 0.12s linear;
            z-index: 2;
            font-size: $fontSizeXS;
            display: flex;
            justify-content: center;
            align-items: center;

            @include rwd(laptop) {
                top: 10%;
                font-size: $fontSizeL;
            }

            span {
                color: $whiteColor;
                padding: 5px 10px;
                z-index: 1;

                @include rwd(laptop) {
                    padding: 10px 20px;
                }
            }

            .bgc {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $proartSecondColor;
                border-radius: 2px;
                opacity: 1;
            }
        }

        #ad-video {
            position: relative;

            &::before {
                content: 'Reklama';
                padding: 0.2rem;
                background-color: $proartSecondColor;
                color: $whiteColor;
                font-size: $fontSizeM;
                position: absolute;
                top: 1rem;
                right: 1rem;
            }

            &.ended {
                &::before {
                    content: 'Wczytywanie video...';
                }
            }
        }

        video,
        iframe {
            width: 100%;
            height: 100%;
        }

        ::-webkit-media-controls-fullscreen-button {
            display: none;
        }

        .video-watermark {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 22px;
            opacity: 0.5;
        }
    }

    .content {
        padding: 10px 0;

        @include rwd(laptop) {
            padding: 10px;
        }

        .title-section {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            border-bottom: 1px solid $proartGrayColor;
            padding-bottom: 10px;
            margin-bottom: 10px;
            align-items: flex-start;
            flex-direction: column;

            @include rwd(tablet) {
                align-items: flex-end;
                flex-direction: row;
            }

            .title {
                text-align: left;
                margin: 0;
                line-height: normal;
                font-size: $fontSizeXXL;

                @include rwd(tablet) {
                    font-size: 30px;
                }
            }

            .material-symbols-outlined {
                font-size: $fontSizeL;
            }

            button {
                border: unset;
                display: flex;
                gap: 4px;
                align-items: center;
                border-radius: 16px;
                color: $fontColor;
                padding: 6px 14px;
                font-size: $fontSizeXS;
                font-weight: 500;
                cursor: pointer;
                background-color: $proartGrayColor;
                will-change: background-color;
                transition: background-color 0.15s linear;

                &:hover {
                    background-color: $grayColor;
                }
            }
        }

        .description-container {
            background-color: $proartGrayColor;
            padding: 10px 20px;
            border-radius: 20px;
            font-size: $fontSizeM;

            h4 {
                margin-bottom: 6px;
                font-weight: 500;
                font-size: $fontSizeS;

                .views {
                    margin-right: 6px;
                }
            }

            .description {
                margin: 0;

                @include rwd(tablet) {
                    font-size: $fontSizeL;
                }
            }
        }
    }

    .link-container {
        text-align: center;
        margin-top: 20px;

        a {
            display: inline-block;
            padding: 5px;
        }
    }

    .material-videos-section {
        padding-top: 0;
    }
}
