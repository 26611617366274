.offer-section {
    scroll-margin-top: 90px;
    padding: 20px;

    @include rwd(tablet) {
        padding: 40px;
    }

    @include rwd(laptop) {
        padding: 60px;
    }

    &.orange {
        background-color: $proartSecondColor;
        color: $whiteColor;

        .section-header {
            background-color: $proartSecondColor;
        }

        .offer-container .offer-block h4 {
            background-color: $proartMainBackgroundColor;
            color: $whiteColor;
        }
    }

    &.gray {
        background-color: $proartGrayColor;
        color: $proartMainFontColor;

        .section-header {
            color: $proartMainFontColor;
        }

        .offer-container .offer-block h4 {
            background-color: $proartSecondColor;
            color: $whiteColor;
        }
    }

    &.navy {
        background-color: $proartMainBackgroundColor;
        color: $whiteColor;

        .section-header {
            color: $whiteColor;
        }

        .offer-container .offer-block h4 {
            background-color: $proartSecondColor;
            color: $whiteColor;
        }
    }

    &.white {
        background-color: $backgroundColorSolidAlternate;
        color: $proartMainFontColor;

        .section-header {
            color: $proartMainFontColor;
        }
    }

    .section-header {
        background-color: transparent;
        letter-spacing: 1px;
        font-weight: 600;
    }

    .main-container {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;

        &.reverse {
            flex-direction: row-reverse;
        }

        @include rwd(laptop) {
            gap: 40px;
            align-items: flex-start;
            flex-wrap: nowrap;
        }

        @include rwd(desktop) {
            align-items: flex-start;
            gap: 80px;
        }

        img {
            order: 2;
            flex: 1;
            max-width: 720px;
            width: 100%;

            @include rwd(laptop) {
                order: 1;
                width: 50%;
            }
        }

        .content {
            flex: 1;

            @include rwd(laptop) {
                min-width: 450px;
                order: 2;
            }

            p {
                text-align: left;
                font-size: $fontSizeM;
                line-height: $fontSizeL;

                @include rwd(tablet) {
                    font-size: $fontSizeL;
                    line-height: $fontSizeXL;
                }

                @include rwd(laptop) {
                    font-size: $fontSizeXL;
                    line-height: $fontSizeXXL;
                }
            }
        }
    }

    h2.section-header {
        padding: 60px 20px;
    }

    .offer-container {
        gap: 20px;
        flex-wrap: wrap;

        .offer-block {
            flex: 1 1 100%;
            all: unset;
            cursor: pointer;

            @include rwd(tablet) {
                flex: 1 1 47%;
            }

            @include rwd(laptop) {
                flex: 1;
            }

            img {
                display: block;
                width: 100%;
            }

            h3 {
                line-height: 40px;
                font-size: $fontSizeL;
                text-align: center;
            }
        }
    }
}
