@charset "UTF-8";
body {
  --background-color: hsl(0, 0%, 90%);
  --background-color-alternate: hsl(0, 0%, 95%);
  --background-color-solid: hsl(0, 0%, 100%);
  --background-color-solid-alternate: hsl(0, 0%, 100%);
  --background-color-solid-section-header: hsl(0, 0%, 100%);
  --background-color-gray: hsl(0, 0%, 80%);
  --font-color: hsl(0, 0%, 0%);
  --main-color: hsl(225, 60%, 40%);
  --main-color-accent: hsl(225, 60%, 20%);
  --main-color-background: hsl(225, 60%, 95%);
  --error-color: hsl(345, 100%, 40%);
  --error-color-accent: hsl(345, 100%, 30%);
  --error-color-background: hsl(345, 100%, 95%);
  --warning-color: hsl(45, 90%, 40%);
  --warning-color-accent: hsl(45, 90%, 30%);
  --warning-color-background: hsl(45, 90%, 95%);
  --success-color: hsl(135, 60%, 40%);
  --success-color-accent: hsl(135, 60%, 30%);
  --success-color-background: hsl(135, 60%, 95%);
  --proart-main-font-color: hsl(214, 46%, 20%);
  --proart-main-color-background: hsl(214, 46%, 20%);
  --proart-gray-color: hsl(210, 9%, 90%);
}

body.dark-theme {
  --background-color: hsl(0, 0%, 10%);
  --background-color-alternate: hsl(0, 0%, 5%);
  --background-color-solid: hsl(0, 0%, 0%);
  --background-color-solid-alternate: hsl(0, 0%, 40%);
  --background-color-solid-section-header: hsl(0, 0%, 60%);
  --background-color-gray: hsl(0, 0%, 20%);
  --font-color: hsl(0, 0%, 100%);
  --main-color: hsl(225, 60%, 40%);
  --main-color-accent: hsl(225, 60%, 60%);
  --main-color-background: hsl(225, 60%, 10%);
  --error-color: hsl(345, 100%, 40%);
  --error-color-accent: hsl(345, 100%, 60%);
  --error-color-background: hsl(345, 100%, 10%);
  --warning-color: hsl(45, 90%, 40%);
  --warning-color-accent: hsl(45, 90%, 60%);
  --warning-color-background: hsl(45, 90%, 10%);
  --success-color: hsl(135, 60%, 40%);
  --success-color-accent: hsl(135, 60%, 60%);
  --success-color-background: hsl(135, 60%, 10%);
  --proart-main-font-color: hsl(214, 46%, 100%);
  --proart-main-color-background: hsl(214, 46%, 20%);
  --proart-gray-color: hsl(210, 9%, 20%);
}

.button {
  border: unset;
  color: hsl(0, 0%, 100%);
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 0;
  width: 100%;
  max-width: 430px;
  border-radius: 3rem;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 500;
}
.button[disabled] {
  opacity: 0.5;
  color: hsl(0, 0%, 50%);
  border: 2.5px solid hsl(0, 0%, 50%);
  pointer-events: none;
}
.button:hover {
  text-decoration: none;
  color: hsl(0, 0%, 100%);
}
.button:hover:before {
  width: 100%;
}
.button.search {
  width: auto;
  font-weight: 300;
  text-transform: none;
  padding: 0;
  border-radius: 0;
}
.button.orange {
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
}
.button.orange:hover {
  background-color: #e98604;
}
.button.primary {
  background-color: var(--proart-main-color-background);
  color: hsl(0, 0%, 100%);
}
.button.primary:after {
  content: ">>";
  margin-left: 0.5rem;
  display: inline-block;
}
.button.primary:hover {
  background-color: var(--proart-main-color-background);
}
.button.orange-link {
  width: auto;
  font-weight: 300;
  text-transform: none;
  padding: 0;
  border-radius: 0;
  color: #e7401d;
  font-weight: 500;
}
.button.white {
  background-color: var(--background-color-solid);
  color: var(--proart-main-font-color);
}
.button.white:hover {
  color: var(--proart-main-font-color);
}
.button.xl {
  font-size: 16px;
}
@media all and (min-width: 500px) {
  .button.xl {
    font-size: 18px;
  }
}
@media all and (min-width: 1280px) {
  .button.xl {
    font-size: 24px;
  }
}
@media all and (min-width: 2000px) {
  .button.xl {
    font-size: 28px;
  }
}

.content-wrapper {
  margin: 0 auto;
  max-width: 1280px;
}

.text-main {
  color: var(--main-color);
}

.text-error {
  color: var(--error-color);
}

.text-warning {
  color: var(--warning-color);
}

.text-success {
  color: var(--success-color);
}

.input {
  height: 2.5rem;
  border-radius: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.input.search {
  border: 1px solid hsl(0, 0%, 100%);
  background: transparent;
  color: hsl(0, 0%, 100%);
}
.input:focus {
  outline: 1px solid hsl(0, 0%, 100%);
}

.hamburger {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: -5px 0;
  padding: 0px;
  justify-content: center;
}
.hamburger span {
  background: hsl(0, 0%, 100%);
  border-radius: 10px;
  height: 4px;
  margin: 4px 0;
  transition: transform 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6), width 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  will-change: transform, width;
  width: 100%;
}
.hamburger span:nth-of-type(1) {
  transform-origin: bottom;
}
.hamburger span:nth-of-type(2) {
  transform-origin: top;
}
.hamburger span:nth-of-type(3) {
  transform-origin: bottom;
}
.hamburger.active span:nth-of-type(1) {
  transform: rotate(45deg) translate(7px, 8px);
}
.hamburger.active span:nth-of-type(2) {
  transform: rotate(-45deg) translate(0px, 0px);
}
.hamburger.active span:nth-of-type(3) {
  width: 50%;
  transform: translate(16px, -6px) rotateZ(45deg);
}

svg {
  width: 44px;
  height: 44px;
  cursor: pointer;
}
svg.facebook path {
  fill: #4b5b73;
}
svg.facebook:hover path {
  fill: hsl(0, 0%, 100%);
}
svg.instagram path {
  fill: #4b5b73;
}
svg.instagram:hover path {
  fill: hsl(0, 0%, 100%);
}
svg.youtube path,
svg.youtube polygon {
  fill: #4b5b73;
}
svg.youtube:hover path,
svg.youtube:hover polygon {
  fill: hsl(0, 0%, 100%);
}
svg.loupe {
  fill: hsl(0, 0%, 100%);
}

h1 {
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
}
@media all and (min-width: 1280px) {
  h1 {
    font-size: 64px;
    line-height: 64px;
  }
}

a {
  text-decoration: none;
}

.text-lead {
  font-size: 28px;
  line-height: 30.8px;
}
@media all and (min-width: 1280px) {
  .text-lead {
    font-size: 36px;
    line-height: 39.6px;
  }
}

.text-center {
  text-align: "center";
}

.text-start {
  text-align: "start";
}

.text-end {
  text-align: "end";
}

.text-title {
  margin: 0;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
}
@media all and (min-width: 1280px) {
  .text-title {
    font-size: 24px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media all and (min-width: 1280px) {
  .row {
    flex-wrap: nowrap;
  }
}
.row.vertical {
  flex-direction: column;
}
.row .col-1 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-1 {
    width: 8.3333333333%;
    flex: 0 0 8.3333333333%;
  }
}
.row .col-2 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-2 {
    width: 16.6666666667%;
    flex: 0 0 16.6666666667%;
  }
}
.row .col-3 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-3 {
    width: 25%;
    flex: 0 0 25%;
  }
}
.row .col-4 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-4 {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
  }
}
.row .col-5 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-5 {
    width: 41.6666666667%;
    flex: 0 0 41.6666666667%;
  }
}
.row .col-6 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-6 {
    width: 50%;
    flex: 0 0 50%;
  }
}
.row .col-7 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-7 {
    width: 58.3333333333%;
    flex: 0 0 58.3333333333%;
  }
}
.row .col-8 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-8 {
    width: 66.6666666667%;
    flex: 0 0 66.6666666667%;
  }
}
.row .col-9 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-9 {
    width: 75%;
    flex: 0 0 75%;
  }
}
.row .col-10 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-10 {
    width: 83.3333333333%;
    flex: 0 0 83.3333333333%;
  }
}
.row .col-11 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-11 {
    width: 91.6666666667%;
    flex: 0 0 91.6666666667%;
  }
}
.row .col-12 {
  width: 100%;
  flex: 0 0 100%;
}
@media all and (min-width: 1280px) {
  .row .col-12 {
    width: 100%;
    flex: 0 0 100%;
  }
}

.items-center {
  display: flex;
  align-items: "center";
}

.content-center {
  display: flex;
  justify-content: "center";
}

.items-stretch {
  display: flex;
  align-items: "stretch";
}

.content-stretch {
  display: flex;
  justify-content: "stretch";
}

.items-space-between {
  display: flex;
  align-items: "space-between";
}

.content-space-between {
  display: flex;
  justify-content: "space-between";
}

.items-start {
  display: flex;
  align-items: "start";
}

.content-start {
  display: flex;
  justify-content: "start";
}

.items-end {
  display: flex;
  align-items: "end";
}

.content-end {
  display: flex;
  justify-content: "end";
}

.big-name-header {
  font-weight: 900;
  letter-spacing: 0;
  text-align: left;
  font-size: 28px;
  line-height: 28px;
  text-transform: uppercase;
}
@media all and (min-width: 500px) {
  .big-name-header {
    font-size: 40px;
    line-height: 40px;
  }
}
@media all and (min-width: 1025px) {
  .big-name-header {
    font-size: 50px;
    line-height: 50px;
    width: 10px;
  }
}

.section-header {
  font-size: 18px;
  text-align: center;
  padding: 20px;
  font-weight: 600;
  color: hsl(0, 0%, 100%);
  background-color: var(--proart-main-color-background);
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0;
}
@media all and (min-width: 500px) {
  .section-header {
    padding: 30px 20px;
    font-size: 24px;
    line-height: 24px;
  }
}
@media all and (min-width: 1025px) {
  .section-header {
    padding: 50px 20px;
    font-size: 28px;
    line-height: 28px;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.skeleton {
  border-radius: 5px;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-square {
  width: 100%;
  height: 0;
  padding-top: 100%;
  display: block;
}

.skeleton-ratio-4-3 {
  width: 100%;
  height: 0;
  padding-top: 75%;
  display: block;
}

.skeleton-pharagraph {
  height: 15px;
  width: 100%;
  margin-bottom: 10px;
}
.skeleton-pharagraph.sm {
  height: 10px;
  margin-bottom: 8px;
}
.skeleton-pharagraph.xl {
  height: 30px;
  margin-bottom: 15px;
}

.skeleton-50-width {
  display: block;
  width: 50%;
  height: 15px;
  margin-bottom: 10px;
}
.skeleton-50-width.sm {
  height: 10px;
  margin-bottom: 8px;
}
.skeleton-50-width.xl {
  height: 36px;
  margin-bottom: 18px;
}
.skeleton-50-width.right {
  margin-left: auto;
}

.skeleton__search-movie {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
.skeleton__search-movie:not(:last-of-type) {
  margin-bottom: 1rem;
}
.skeleton__search-movie .skeleton-pharagraph:first-of-type {
  width: 45%;
}
.skeleton__search-movie .skeleton-pharagraph:last-of-type {
  margin-bottom: 0;
}

.notification-container {
  position: fixed;
  right: 0;
  bottom: 50px;
  padding: 0 10px;
  width: 100%;
  z-index: 101;
}
@keyframes fade {
  0% {
    opacity: 0;
    max-height: 0;
    margin-top: 0;
    padding: 0 20px;
  }
  50% {
    opacity: 0;
    max-height: 100px;
    margin-top: 10px;
    padding: 20px 20px;
  }
  100% {
    opacity: 1;
  }
}
@media all and (min-width: 500px) {
  .notification-container {
    bottom: 10px;
    width: 500px;
  }
}
.notification-container.centered {
  right: 50%;
  bottom: 50%;
  transform: translate(50%, -50%);
}
.notification-container .notification {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 20px 20px;
  gap: 10px;
  max-height: 100px;
  opacity: 1;
  animation: fade 0.6s linear;
  overflow: hidden;
  transition: opacity 0.3s linear, max-height 0.3s linear 0.3s, margin-top 0.3s linear 0.3s, padding 0.3s linear 0.3s;
}
.notification-container .notification-message {
  flex-basis: 86%;
  font-size: 16px;
}
.notification-container .notification-icon {
  flex-basis: 7%;
  display: flex;
  align-items: center;
}
.notification-container .notification-close {
  flex-basis: 7%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.notification-container .notification.success {
  background-color: var(--success-color-background);
  color: var(--success-color);
  border: 1px solid var(--success-color);
}
.notification-container .notification.warning {
  background-color: var(--warning-color-background);
  color: var(--warning-color);
  border: 1px solid var(--warning-color);
}
.notification-container .notification.error {
  background-color: var(--error-color-background);
  color: var(--error-color);
  border: 1px solid var(--error-color);
}
.notification-container .notification.info {
  background-color: var(--main-color-background);
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.notification-container .notification.dismissed {
  max-height: 0;
  opacity: 0;
  margin-top: 0;
  padding: 0 20px;
  border: 0;
  pointer-events: none;
}

.ui-pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
.ui-pagination div.page {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--proart-main-color-background);
  color: hsl(0, 0%, 100%);
  border-radius: 17.5px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.15s linear;
}
@media all and (min-width: 500px) {
  .ui-pagination div.page {
    border-radius: 22.5px;
    width: 45px;
    height: 45px;
    font-size: 24px;
  }
}
.ui-pagination div.page:hover, .ui-pagination div.page.active {
  background-color: #e7401d;
}
.ui-pagination div.dots {
  font-size: 18px;
  align-self: flex-end;
  font-weight: 700;
  letter-spacing: 1px;
}
@media all and (min-width: 500px) {
  .ui-pagination div.dots {
    letter-spacing: 2px;
    font-size: 24px;
  }
}

.ui-sort-select {
  position: relative;
  display: flex;
  max-width: 180px;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  overflow: hidden;
  color: var(--proart-main-font-color);
}
.ui-sort-select select {
  appearance: none;
  border: 0;
  box-shadow: none;
  padding-left: 20px;
  flex: 1;
  color: var(--proart-main-font-color);
  background-image: none;
  background-color: var(--proart-gray-color);
  cursor: pointer;
}
.ui-sort-select select::-ms-expand {
  display: none;
}
.ui-sort-select select:focus-visible {
  outline: 0;
}
.ui-sort-select select option {
  padding: 5px;
  border-bottom: 1px solid var(--proart-main-color-background);
}
.ui-sort-select::after {
  content: "▼";
  position: absolute;
  top: 0;
  right: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  color: var(--proart-main-font-color);
  pointer-events: none;
}

.ui-datepicker-input {
  max-width: 180px;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: var(--proart-gray-color);
  color: var(--proart-main-font-color);
  border-radius: 5px;
  appearance: none;
  box-shadow: none;
  background-image: none;
  outline: none;
  padding-left: 20px;
  border: 0;
}
.ui-datepicker-input:focus {
  outline: none;
}

.ui-datepicker {
  width: 280px;
  padding: 5px;
  background: var(--background-color-alternate);
  border-radius: 4px;
  box-shadow: 0 4px 40px -8px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.ui-datepicker.active {
  opacity: 1;
}
.ui-datepicker .ui-datepicker-header {
  height: 40px;
  padding: 3px;
  margin-bottom: 10px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  text-align: center;
  line-height: 34px;
}
.ui-datepicker .ui-datepicker-month,
.ui-datepicker .ui-datepicker-year {
  appearance: none;
  border: 0;
  background: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: var(--proart-main-font-color);
  margin: 0 1px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  cursor: pointer;
  text-indent: 9999px;
  overflow: hidden;
  border-radius: 3px;
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background: hsl(0, 0%, 50%);
}
.ui-datepicker .ui-datepicker-prev {
  float: left;
}
.ui-datepicker .ui-datepicker-prev:after {
  transform: rotate(45deg);
  margin-left: 15px;
}
.ui-datepicker .ui-datepicker-next {
  float: right;
}
.ui-datepicker .ui-datepicker-next:after {
  transform: rotate(-135deg);
  margin-left: 13px;
}
.ui-datepicker .ui-datepicker-prev:after,
.ui-datepicker .ui-datepicker-next:after {
  content: "";
  position: absolute;
  display: block;
  margin-top: -10px;
  width: 6px;
  height: 6px;
  border-left: 2px solid var(--proart-main-font-color);
  border-bottom: 2px solid var(--proart-main-font-color);
  pointer-events: none;
}
.ui-datepicker .ui-datepicker-calendar {
  width: 100%;
  text-align: center;
}
.ui-datepicker .ui-datepicker-calendar thead tr th {
  width: 40px;
  padding-bottom: 6px;
}
.ui-datepicker .ui-datepicker-calendar thead tr th span {
  display: block;
  width: 100%;
  padding: 0;
  color: hsl(0, 0%, 50%);
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td {
  padding-right: 3px;
  padding-bottom: 3px;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td:first-child {
  padding-left: 3px;
}
.ui-datepicker .ui-state-default {
  display: block;
  text-decoration: none;
  color: var(--proart-main-font-color);
  height: 34px;
  line-height: 34px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
}
.ui-datepicker .ui-state-default:hover {
  background: hsl(0, 0%, 50%);
  text-decoration: none;
  color: #e98604;
}
.ui-datepicker .ui-state-highlight {
  color: #e7401d;
}
.ui-datepicker .ui-state-active:not(.ui-state-highlight) {
  color: #e7401d;
  background: var(--proart-main-color-background);
}
.ui-datepicker .ui-datepicker-unselectable .ui-state-default {
  color: hsl(0, 0%, 50%);
  pointer-events: none;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.popup .wrapper {
  position: relative;
  max-width: 100%;
}
.popup .wrapper img {
  padding: 20px;
  pointer-events: none;
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
}
.popup .wrapper button {
  position: absolute;
  top: 36px;
  right: 36px;
  font-size: 28px;
  background-color: transparent;
  border: none;
  color: hsl(0, 0%, 100%);
  text-shadow: 0 0 5px hsl(0, 0%, 0%);
  cursor: pointer;
}

.navbar {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: hsla(0, 0%, 0%, 0.75);
}
.navbar::before {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.navbar.scrolled, .navbar.active {
  background-color: hsla(0, 0%, 0%, 0.75);
}
@media all and (min-width: 1280px) {
  .navbar {
    background-color: hsla(0, 0%, 0%, 0.5);
  }
  .navbar.scrolled, .navbar.active {
    background-color: hsla(0, 0%, 0%, 0.5);
  }
}

.navbar-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navbar-hamburger {
  background: transparent;
  padding: 0;
  border: unset;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  margin-left: 1rem;
  order: 2;
  position: relative;
  box-sizing: content-box;
}
@media all and (min-width: 1280px) {
  .navbar-hamburger {
    margin-left: auto;
    display: none;
  }
}

.navbar-brand {
  height: 2rem;
}
.navbar-brand img {
  height: 40px;
  width: auto;
}

.ui-theme-changer {
  display: none;
  order: 3;
}
@media all and (min-width: 500px) {
  .ui-theme-changer {
    margin-left: 1.5rem;
  }
}
@media all and (min-width: 1280px) {
  .ui-theme-changer {
    margin-left: unset;
    order: unset;
  }
}

.navbar-menu__box {
  gap: 0;
  display: none;
  order: 4;
  width: 100%;
}
.navbar-menu__box.active {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.navbar-menu__box.active .navbar-menu,
.navbar-menu__box.active .navbar-search {
  display: flex;
}
@media all and (min-width: 1280px) {
  .navbar-menu__box {
    margin-left: auto;
    display: flex;
    align-items: center;
    order: unset;
    width: auto;
  }
  .navbar-menu__box.active {
    display: flex;
    width: auto;
    flex-direction: row;
  }
  .navbar-menu__box.active .navbar-menu {
    display: block;
  }
  .navbar-menu__box.active .navbar-search {
    display: flex;
  }
}

.navbar-menu {
  list-style: none;
  padding: 2rem 0 0 0;
  margin: 0;
  display: none;
  width: 100%;
  flex-direction: column;
}
@media all and (min-width: 1280px) {
  .navbar-menu {
    margin-left: auto;
    display: block;
    padding: 0;
  }
}

.navbar-menu__item {
  display: inline;
  padding: 0.75rem 0;
  position: relative;
}
@media all and (min-width: 1280px) {
  .navbar-menu__item {
    margin-right: 45px;
    padding: 0;
  }
  .navbar-menu__item::after {
    content: "";
    display: block;
    height: 33px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -33px;
  }
  .navbar-menu__item:hover .navbar-dropdown {
    opacity: 1;
    pointer-events: all;
  }
}
.navbar-menu__item .navbar-dropdown {
  display: none;
}
@media all and (min-width: 1280px) {
  .navbar-menu__item .navbar-dropdown {
    display: block;
    height: fit-content;
    position: absolute;
    list-style: none;
    margin-top: 33px;
    will-change: transform;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 0 0 10px 10px;
    font-size: 18px;
    overflow: hidden;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    pointer-events: none;
  }
  .navbar-menu__item .navbar-dropdown::before {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .navbar-menu__item .navbar-dropdown-item {
    text-align: center;
    width: 100%;
    position: relative;
    background-color: hsla(0, 0%, 0%, 0.5);
  }
  .navbar-menu__item .navbar-dropdown-item a {
    padding: 0.25rem 0.75rem;
    display: block;
    width: 100%;
    color: hsl(0, 0%, 100%);
    text-decoration: none;
  }
  .navbar-menu__item .navbar-dropdown-item a:hover {
    background-color: hsla(0, 0%, 0%, 0.5);
  }
  .navbar-menu__item .navbar-dropdown-item a:first-of-type {
    padding: 0.5rem 0.75rem 0.25rem;
  }
  .navbar-menu__item .navbar-dropdown-item a:last-of-type {
    padding: 0.25rem 0.75rem 0.5rem;
  }
}

.navbar-menu__link {
  color: hsl(0, 0%, 100%);
  text-decoration: none;
  font-size: 24px;
  display: inline-block;
}
.navbar-menu__link:hover {
  color: hsl(0, 0%, 100%);
}
@media all and (min-width: 1280px) {
  .navbar-menu__link {
    font-size: 18px;
  }
}

.navbar-search {
  align-items: stretch;
  gap: 1rem;
  display: none;
}
.navbar-search.active {
  display: flex;
  width: 100%;
  order: 5;
  margin-top: 1rem;
  justify-content: center;
}
.navbar-search.active .input {
  width: 100%;
}
@media all and (min-width: 1025px) {
  .navbar-search.active .input {
    width: 480px;
  }
}
@media all and (min-width: 1280px) {
  .navbar-search.active .input {
    width: 240px;
  }
}
@media all and (min-width: 1280px) {
  .navbar-search.active {
    margin-top: 0;
    order: unset;
    width: auto;
    justify-content: flex-start;
  }
}
.navbar-search .input {
  font-size: 14px;
}
.navbar-search .input::placeholder {
  color: hsl(0, 0%, 100%);
}
@media all and (min-width: 1280px) {
  .navbar-search .input {
    width: 240px;
  }
}
.navbar-search .button {
  flex-shrink: 0;
}
@media all and (min-width: 1280px) {
  .navbar-search {
    margin-right: 45px;
    display: flex;
  }
}
.navbar-search.open .input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.navbar-search.open .search-results__box {
  display: block;
  opacity: 1;
  z-index: inherit;
  box-shadow: 0 4px 4px hsl(0, 0%, 50%);
}

.navbar-search__mobile-switcher {
  display: block;
  margin-left: auto;
  width: 1.75rem;
  height: 1.75rem;
  background-color: transparent;
  border: unset;
  color: hsl(0, 0%, 100%);
  align-self: flex-start;
  margin-top: 0.5rem;
}
.navbar-search__mobile-switcher svg {
  width: inherit;
  height: inherit;
}
@media all and (min-width: 1280px) {
  .navbar-search__mobile-switcher {
    display: none;
  }
}

.input-search-zone {
  position: relative;
  width: 100%;
}
@media all and (min-width: 1025px) {
  .input-search-zone {
    width: auto;
  }
}

.search-results__box {
  border: 1px solid hsl(0, 0%, 100%);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: hsl(0, 0%, 100%);
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  opacity: 0;
  min-width: 100%;
  max-width: 100%;
  max-height: 60vh;
  overflow: hidden;
}
@media all and (min-width: 1025px) {
  .search-results__box {
    max-height: 400px;
    max-width: 320px;
  }
}

.search-results__list {
  padding: 10px 10px;
  max-height: inherit;
  overflow-y: auto;
}
.search-results__list::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.search-results__list::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: hsl(0, 0%, 100%);
}
.search-results__list::-webkit-scrollbar-track:hover {
  background-color: hsl(0, 0%, 90%);
}
.search-results__list::-webkit-scrollbar-track:active {
  background-color: hsl(0, 0%, 90%);
}
.search-results__list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #e98604;
}
.search-results__list::-webkit-scrollbar-thumb:hover {
  background-color: #e7401d;
}
.search-results__list::-webkit-scrollbar-thumb:active {
  background-color: #e7401d;
}

.search-results {
  color: var(--proart-main-color-background);
  font-size: 14px;
  padding-left: 13px;
}

.search-result {
  color: var(--proart-main-color-background);
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
.search-result:last-of-type {
  margin-bottom: 0;
}

.search-result__title {
  margin: 0 0 5px 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result__description {
  margin: 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1rem;
  max-height: 2rem;
}

.search-result__text {
  width: calc(100% - 80px - 10px);
}

.search-result__figure {
  margin: 0;
  flex-shrink: 0;
  overflow: hidden;
  display: block;
}
.search-result__figure img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  display: block;
}

.footer {
  background-color: var(--proart-main-color-background);
  padding-top: 55px;
  padding-bottom: 55px;
  color: hsl(0, 0%, 100%);
  font-weight: 300;
}
.footer a {
  font-weight: inherit;
  color: hsl(0, 0%, 100%);
  text-decoration: none;
}
.footer a:hover {
  color: inherit;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}
@media all and (min-width: 1025px) {
  .footer-container {
    width: 900px;
  }
}
@media all and (min-width: 1280px) {
  .footer-container {
    width: 1280px;
  }
}
.footer-title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
}
.footer-title.content {
  margin: 0;
}
.footer-title.uppercase {
  text-transform: uppercase;
}
.footer-menu__box {
  flex-shrink: 0;
}
.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-address__column {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
@media all and (min-width: 1280px) {
  .footer-address__column {
    gap: 3rem;
  }
}
.footer-address {
  font-style: normal;
}
.footer .skeleton-pharagraph {
  width: 180px;
  height: 20px;
  margin-bottom: 6px;
}
.footer-social__box {
  flex-shrink: 0;
}
.footer-social {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.1rem;
}
.footer-social__link {
  width: 40px;
  height: 40px;
}
.footer-social__link svg {
  width: inherit;
  height: inherit;
}
.footer-author {
  margin: 50px auto 0 auto;
  font-size: 14px;
}
.footer .contact-message-popup-button {
  border: 0;
  background-color: transparent;
  color: #e98604;
  box-shadow: none;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
@media all and (min-width: 1025px) {
  .footer .contact-message-popup-button {
    font-size: 18px;
  }
}
.footer .contact-message-popup-button:hover {
  text-decoration: underline;
}

a.footer-logo__download {
  display: flex;
  flex-direction: column;
  color: #e98604;
  line-height: 2rem;
}
a.footer-logo__download img {
  height: 30px;
  width: auto;
}

.cookie-popup {
  display: none;
  position: fixed;
  z-index: 9;
  left: 1rem;
  bottom: 1rem;
  max-width: calc(100% - 2rem);
  width: 500px;
  background-color: var(--proart-gray-color);
  border: 1px solid var(--proart-main-color-background);
  padding: 1rem;
  border-radius: 20px;
}
.cookie-popup.open {
  display: block;
}

.cookie-popup__cta {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.cookie-popup__cta .button.orange {
  padding: 0.5rem 2rem;
}

.cookie-popup__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
}

.cookie-popup__description {
  margin: 0;
  font-size: 14px;
}

body.dark-theme .cookie-popup__link {
  color: #e98604;
}

.contact-message-popup {
  display: none;
}
@keyframes checkAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 10px;
  }
}
.contact-message-popup.active {
  display: flex;
}
.contact-message-popup.active .form-container {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  background-color: var(--background-color);
  max-height: 92vh;
  overflow-y: auto;
  position: relative;
}
.contact-message-popup.active .form-container .input-container label,
.contact-message-popup.active .form-container .input-container input,
.contact-message-popup.active .form-container .input-container textarea {
  background-color: var(--background-color);
}

.form-container .close-button {
  background-color: transparent;
  color: var(--font-color);
  box-shadow: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--font-color);
  border: none;
  outline: none;
}
.form-container textarea {
  height: 120px;
  resize: none;
}
.form-container .star::after {
  content: " *";
  color: #e7401d;
}
.form-container p {
  font-size: 14px;
}
.form-container a {
  color: var(--font-color);
}
.form-container .button-container {
  margin: 0 auto;
  width: fit-content;
}
.form-container .button-container .button {
  padding: 1rem 3rem;
}
.form-container .checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}
.form-container .checkbox-container .custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.form-container .checkbox-container .custom-checkbox:checked ~ .checkmark {
  background-color: #2196f3;
  box-shadow: 0 3px 7px rgba(33, 150, 243, 0.3);
}
.form-container .checkbox-container .custom-checkbox:checked ~ .checkmark:after {
  display: block;
  animation: checkAnim 0.2s forwards;
}
.form-container .checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.form-container .checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid hsl(0, 0%, 100%);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.form-container .input-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
  width: 100%;
  margin-bottom: 12px;
}
.form-container .input-container label {
  font-size: 14px;
  color: var(--font-color);
  font-weight: 300;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: var(--background-color-solid);
  width: fit-content;
  font-family: "Montserrat", sans-serif;
}
.form-container .input-container input[type=text],
.form-container .input-container textarea {
  font-size: 16px;
  padding: 11px 10px;
  border: 1px hsl(0, 0%, 50%) solid;
  border-radius: 5px;
  background: var(--background-color-solid);
  font-family: "Montserrat", sans-serif;
  color: var(--font-color);
  -webkit-text-fill-color: var(--font-color);
}
.form-container .input-container input[type=text]::placeholder,
.form-container .input-container textarea::placeholder {
  color: hsl(0, 0%, 50%);
}
.form-container .input-container input[type=text]:-internal-autofill-selected,
.form-container .input-container textarea:-internal-autofill-selected {
  background-color: none !important;
  color: var(--font-color) !important;
}
.form-container .input-container input[type=text]:focus,
.form-container .input-container textarea:focus {
  outline: none;
  border: 1px var(--font-color) solid;
}

.develop-your-business-with-us h2.section-header {
  color: var(--font-color);
  background-color: var(--background-color-solid-section-header);
}
.develop-your-business-with-us .block-container {
  display: grid;
  grid-template-columns: 1fr;
}
@media all and (min-width: 1025px) {
  .develop-your-business-with-us .block-container {
    grid-template-columns: 1fr 1fr;
  }
}
.develop-your-business-with-us .block-container .block {
  padding: 30px 20px;
}
@media all and (min-width: 1025px) {
  .develop-your-business-with-us .block-container .block {
    display: flex;
    flex-direction: column;
  }
}
@media all and (min-width: 1280px) {
  .develop-your-business-with-us .block-container .block {
    padding: 40px;
  }
}
.develop-your-business-with-us .block-container .block.gray {
  background-color: var(--proart-gray-color);
}
.develop-your-business-with-us .block-container .block.blue {
  background-color: var(--proart-main-color-background);
}
.develop-your-business-with-us .block-container .block.orange {
  background-color: #e7401d;
}
.develop-your-business-with-us .block-container .block.orange a {
  background-color: var(--proart-main-color-background);
  color: hsl(0, 0%, 100%);
}
.develop-your-business-with-us .block-container .block.white {
  background-color: var(--background-color-solid-alternate);
}
.develop-your-business-with-us .block-container .block.blue a, .develop-your-business-with-us .block-container .block.gray a, .develop-your-business-with-us .block-container .block.white a {
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
}
.develop-your-business-with-us .block-container .block:nth-child(1), .develop-your-business-with-us .block-container .block:nth-child(2) {
  color: hsl(0, 0%, 100%);
}
.develop-your-business-with-us .block-container .block h3 {
  font-weight: 900;
  letter-spacing: 0;
  text-align: left;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
}
@media all and (min-width: 500px) {
  .develop-your-business-with-us .block-container .block h3 {
    font-size: 40px;
    line-height: 40px;
  }
}
@media all and (min-width: 1025px) {
  .develop-your-business-with-us .block-container .block h3 {
    width: 10px;
    text-align: left;
  }
}
.develop-your-business-with-us .block-container .block .content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  text-align: justify;
}
@media all and (min-width: 1280px) {
  .develop-your-business-with-us .block-container .block .content {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
  }
}
.develop-your-business-with-us .block-container .block .content img {
  width: 100%;
}
@media all and (min-width: 500px) {
  .develop-your-business-with-us .block-container .block .content img {
    width: auto;
    max-width: 400px;
  }
}
@media all and (min-width: 1025px) {
  .develop-your-business-with-us .block-container .block .content img {
    max-width: 100%;
  }
}
@media all and (min-width: 1280px) {
  .develop-your-business-with-us .block-container .block .content img {
    width: 47%;
  }
}
.develop-your-business-with-us .block-container .block .content p {
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  text-align: left;
}
@media all and (min-width: 500px) {
  .develop-your-business-with-us .block-container .block .content p {
    max-width: 400px;
  }
}
@media all and (min-width: 1025px) {
  .develop-your-business-with-us .block-container .block .content p {
    font-size: 18px;
    line-height: 24px;
    max-width: none;
  }
}
@media all and (min-width: 1280px) {
  .develop-your-business-with-us .block-container .block .content p {
    width: 47%;
  }
}
.develop-your-business-with-us .block-container .block .button-container {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
}
@media all and (min-width: 1025px) {
  .develop-your-business-with-us .block-container .block .button-container {
    align-self: center;
    height: 100%;
    align-items: flex-end;
  }
}
@media all and (min-width: 500px) {
  .develop-your-business-with-us .block-container .block .button-container a {
    padding: 0.7rem 3rem;
    text-transform: uppercase;
    font-size: 18px;
  }
}

.hero-banner-slider {
  background-color: var(--proart-main-color-background);
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  padding-top: 225px;
}
@media all and (min-width: 1025px) {
  .hero-banner-slider {
    aspect-ratio: 16/9;
  }
}
@media all and (min-width: 1280px) {
  .hero-banner-slider .content-wrapper {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.swiper.hero-banner-slider__swiper {
  color: hsl(0, 0%, 100%);
  padding-left: 1rem;
  padding-right: 1rem;
}
.swiper.hero-banner-slider__swiper h1,
.swiper.hero-banner-slider__swiper h2 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.hero-banner-slider__content {
  width: 100%;
  margin-bottom: 60px;
}
@media all and (min-width: 1280px) {
  .hero-banner-slider__content {
    margin-bottom: 120px;
  }
}
.hero-banner-slider__content .text-lead {
  margin: 0;
  text-align: start;
}

.hero-banner-slider__cta {
  margin-bottom: 40px;
  width: 100%;
}
.hero-banner-slider__cta p {
  margin-top: 0;
}
.hero-banner-slider__cta .content-end {
  justify-content: start;
  margin-top: 3rem;
}
@media all and (min-width: 1280px) {
  .hero-banner-slider__cta .content-end {
    justify-content: end;
    margin-top: 0;
  }
}

.hero-banner-slider__partner {
  height: 45px;
  display: inline-block;
}
.hero-banner-slider__partner:not(:last-of-type) {
  margin-right: 50px;
}
.hero-banner-slider__partner.promax {
  height: 40px;
  margin-bottom: 5px;
}
.hero-banner-slider__partner img {
  height: inherit;
  width: auto;
}

.proart-in-numbers .container {
  margin: 20px auto;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
@media all and (min-width: 1025px) {
  .proart-in-numbers .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}
.proart-in-numbers .container .block {
  aspect-ratio: 4/3;
  max-width: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media all and (min-width: 500px) {
  .proart-in-numbers .container .block {
    flex: 1;
    max-width: 500px;
    min-width: 400px;
  }
}
@media all and (min-width: 1280px) {
  .proart-in-numbers .container .block {
    padding: 50px;
    max-width: 750px;
  }
}
.proart-in-numbers .container .block h3 span {
  display: block;
}
.proart-in-numbers .container .block h3 span.title {
  font-weight: 900;
  letter-spacing: 0;
  font-size: 40px;
  line-height: 40px;
}
@media all and (min-width: 500px) {
  .proart-in-numbers .container .block h3 span.title {
    font-size: 50px;
    line-height: 50px;
  }
}
@media all and (min-width: 1025px) {
  .proart-in-numbers .container .block h3 span.title {
    font-size: 72px;
    line-height: 72px;
  }
}
@media all and (min-width: 1280px) {
  .proart-in-numbers .container .block h3 span.title {
    font-size: 114px;
    line-height: 114px;
  }
}
.proart-in-numbers .container .block h3 span.subtitle {
  font-weight: 800;
  font-size: 24px;
}
@media all and (min-width: 500px) {
  .proart-in-numbers .container .block h3 span.subtitle {
    font-size: 28px;
    line-height: 1.6rem;
  }
}
@media all and (min-width: 1025px) {
  .proart-in-numbers .container .block h3 span.subtitle {
    font-size: 36px;
  }
}
@media all and (min-width: 1280px) {
  .proart-in-numbers .container .block h3 span.subtitle {
    line-height: 40px;
  }
}
.proart-in-numbers .container .block p {
  text-align: left;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
}
@media all and (min-width: 500px) {
  .proart-in-numbers .container .block p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media all and (min-width: 1025px) {
  .proart-in-numbers .container .block p {
    font-size: 24px;
    line-height: 28px;
  }
}
.proart-in-numbers .container .block:first-of-type, .proart-in-numbers .container .block:last-of-type {
  position: relative;
}
.proart-in-numbers .container .block:first-of-type::before, .proart-in-numbers .container .block:last-of-type::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}
.proart-in-numbers .container .block:first-of-type {
  color: var(--proart-main-font-color);
}
.proart-in-numbers .container .block:first-of-type::before {
  background-image: url("/data/home/telewizor-tlo.png");
}
.proart-in-numbers .container .block:last-of-type {
  color: var(--proart-main-font-color);
}
.proart-in-numbers .container .block:last-of-type::before {
  background-image: url("/data/home/ludzie-tlo.png");
}

body.dark-theme .proart-in-numbers .block:first-of-type::before, body.dark-theme .proart-in-numbers .block:last-of-type::before {
  filter: invert(1) brightness(5);
}

.see-the-latest-premiers .section-header {
  background-color: var(--background-color-solid-section-header);
  color: var(--proart-main-font-color);
}
.see-the-latest-premiers .button {
  color: hsl(0, 0%, 100%);
  background-color: #e7401d;
  padding: 0.7rem 3rem;
  text-transform: uppercase;
}
.see-the-latest-premiers .container {
  background-color: var(--proart-gray-color);
  padding: 20px;
}
@media all and (min-width: 1025px) {
  .see-the-latest-premiers .container {
    padding: 40px 30px;
  }
}
.see-the-latest-premiers .container .promoted-blocks-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}
@media all and (min-width: 1025px) {
  .see-the-latest-premiers .container .promoted-blocks-container {
    align-items: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 40px;
  }
}
@media all and (min-width: 1280px) {
  .see-the-latest-premiers .container .promoted-blocks-container {
    gap: 20px;
  }
}
.see-the-latest-premiers .container .promoted-blocks-container .promoted-block {
  min-width: 300px;
  width: 100%;
  max-width: 500px;
}
.see-the-latest-premiers .container .promoted-blocks-container .promoted-block figure {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}
.see-the-latest-premiers .container .promoted-blocks-container .promoted-block img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  display: block;
}
.see-the-latest-premiers .container .promoted-blocks-container .promoted-block .content {
  background-color: var(--proart-main-color-background);
  padding: 12px 10px;
  color: hsl(0, 0%, 100%);
  height: 106px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.see-the-latest-premiers .container .promoted-blocks-container .promoted-block .content .title {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.see-the-latest-premiers .container .promoted-blocks-container .promoted-block .content .title h3 {
  text-align: center;
  padding-bottom: 6px;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  min-width: 0;
  width: 100%;
}
.see-the-latest-premiers .container .promoted-blocks-container .promoted-block .content p {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.team {
  background-color: hsl(0, 0%, 100%);
}

h2.team-title {
  background-color: var(--background-color-solid-section-header);
  color: var(--proart-main-font-color);
}

.team-picture {
  background-color: var(--proart-main-color-background);
  padding-bottom: 5px;
}
.team-picture img {
  display: block;
  width: 100%;
}

.team-content {
  color: hsl(0, 0%, 100%);
  background-color: var(--proart-main-color-background);
  padding: 25px 0 30px;
  margin-top: -1px;
}

.team-cta {
  text-align: center;
  margin: 0 1rem;
}

.latest-vod {
  background-color: hsl(0, 0%, 100%);
}

h2.latest-vod__title {
  margin: 0;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  background-color: var(--background-color-solid-section-header);
  color: var(--proart-main-font-color);
}

.latest-vod__items {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 45px;
  justify-content: center;
}
.latest-vod__items > * {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
@media all and (min-width: 1025px) {
  .latest-vod__items > * {
    max-width: unset;
  }
}
@media all and (min-width: 1025px) {
  .latest-vod__items {
    flex-direction: row;
  }
  .latest-vod__items > * {
    width: 33%;
  }
}

.latest-vod__item {
  background-color: #e7401d;
}
.latest-vod__item:hover {
  color: hsl(0, 0%, 100%);
}

.latest-vod__item-figure {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  display: block;
}
.latest-vod__item-figure .skeleton,
.latest-vod__item-figure img:not(.latest-vod__series-thumbnail) {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  width: 100%;
}
.latest-vod__item-figure .latest-vod__series-thumbnail {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.latest-vod__item-title {
  padding: 0 1rem 1rem;
  color: hsl(0, 0%, 100%);
  text-align: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.latest-vod__item-title:hover {
  color: hsl(0, 0%, 100%);
}

.latest-vod__content {
  color: hsl(0, 0%, 100%);
  background-color: var(--proart-main-color-background);
  padding: 20px;
}
@media all and (min-width: 1025px) {
  .latest-vod__content {
    padding: 40px 30px;
  }
}

.latest-vod__cta {
  text-align: center;
  margin: 0 1rem;
}

.banner-offer-wrapper {
  position: relative;
  aspect-ratio: 2.286/1;
  height: 420px;
  width: 100%;
  background-color: var(--proart-main-color-background);
}
@media all and (min-width: 500px) {
  .banner-offer-wrapper {
    height: auto;
    min-height: 500px;
  }
}
@media all and (min-width: 1025px) {
  .banner-offer-wrapper {
    min-height: auto;
  }
}
.banner-offer-wrapper .content-wrapper {
  position: absolute;
}
.banner-offer-wrapper .content-wrapper h1 {
  text-align: left;
  color: hsl(0, 0%, 100%);
  margin: 0;
  letter-spacing: 2px;
  padding: 120px 10px 10px;
  text-shadow: 1px 1px 2px black;
}
@media all and (min-width: 500px) {
  .banner-offer-wrapper .content-wrapper h1 {
    padding: 160px 20px 10px;
  }
}
@media all and (min-width: 1025px) {
  .banner-offer-wrapper .content-wrapper h1 {
    text-shadow: none;
    padding: 180px 50px 10px;
  }
}
@media all and (min-width: 1280px) {
  .banner-offer-wrapper .content-wrapper h1 {
    text-shadow: none;
    padding: 260px 100px 10px;
  }
}
.banner-offer-wrapper .content-wrapper h1 span {
  display: block;
}
.banner-offer-wrapper .content-wrapper h1 span.title {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
}
@media all and (min-width: 500px) {
  .banner-offer-wrapper .content-wrapper h1 span.title {
    font-size: 50px;
    line-height: 50px;
  }
}
@media all and (min-width: 1025px) {
  .banner-offer-wrapper .content-wrapper h1 span.title {
    font-weight: 900;
    font-size: 72px;
    line-height: 72px;
  }
}
.banner-offer-wrapper .content-wrapper h1 span.subtitle {
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 5px;
}
@media all and (min-width: 500px) {
  .banner-offer-wrapper .content-wrapper h1 span.subtitle {
    font-size: 40px;
    line-height: 40px;
  }
}
@media all and (min-width: 1025px) {
  .banner-offer-wrapper .content-wrapper h1 span.subtitle {
    margin-bottom: 18px;
    font-size: 50px;
    line-height: 50px;
  }
}
.banner-offer-wrapper img,
.banner-offer-wrapper .skeleton {
  display: block !important;
  width: 100%;
  height: 420px;
  object-position: -280px;
  object-fit: cover;
}
@media all and (min-width: 500px) {
  .banner-offer-wrapper img,
  .banner-offer-wrapper .skeleton {
    object-position: inherit;
    height: auto;
    min-height: 500px;
  }
}
@media all and (min-width: 1025px) {
  .banner-offer-wrapper img,
  .banner-offer-wrapper .skeleton {
    object-position: center;
    min-height: auto;
  }
}
.banner-offer-wrapper .skeleton {
  aspect-ratio: 2.286/1;
}

.offer-offer-for-business .content-wrapper {
  background-color: var(--background-color-solid-alternate);
  padding-top: 36px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media all and (min-width: 500px) {
  .offer-offer-for-business .content-wrapper {
    padding-left: 2rem;
    padding-top: 60px;
  }
}
@media all and (min-width: 1025px) {
  .offer-offer-for-business .content-wrapper {
    gap: 10px;
    padding-left: 60px;
  }
}
.offer-offer-for-business .content-wrapper.no-content {
  padding: 0;
}
.offer-offer-for-business .content-wrapper.no-content .image-container {
  flex: 1;
  aspect-ratio: 16/9;
}
.offer-offer-for-business .content-wrapper.no-content .image-container img {
  max-height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
}
.offer-offer-for-business .content-wrapper .content {
  flex: 1 0 47%;
  padding: 10px 0;
  color: var(--proart-main-font-color);
}
@media all and (min-width: 1025px) {
  .offer-offer-for-business .content-wrapper .content {
    max-width: 640px;
  }
}
.offer-offer-for-business .content-wrapper .content h3 {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}
@media all and (min-width: 500px) {
  .offer-offer-for-business .content-wrapper .content h3 {
    font-size: 24px;
    line-height: 28px;
  }
}
@media all and (min-width: 1025px) {
  .offer-offer-for-business .content-wrapper .content h3 {
    font-size: 28px;
    line-height: 36px;
  }
}
.offer-offer-for-business .content-wrapper .content p {
  margin-bottom: 30px;
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}
@media all and (min-width: 500px) {
  .offer-offer-for-business .content-wrapper .content p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media all and (min-width: 1025px) {
  .offer-offer-for-business .content-wrapper .content p {
    font-size: 24px;
    line-height: 28px;
  }
}
.offer-offer-for-business .content-wrapper .content .sign {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.offer-offer-for-business .content-wrapper .content .sign .name {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}
@media all and (min-width: 1025px) {
  .offer-offer-for-business .content-wrapper .content .sign .name {
    font-size: 24px;
    line-height: 24px;
  }
}
.offer-offer-for-business .content-wrapper .content .sign .job-position {
  font-size: 16px;
}
@media all and (min-width: 1025px) {
  .offer-offer-for-business .content-wrapper .content .sign .job-position {
    font-size: 18px;
  }
}
.offer-offer-for-business .content-wrapper .image-container {
  display: flex;
  justify-content: center;
  flex: 0 0 50%;
}
.offer-offer-for-business .content-wrapper .image-container img {
  max-height: 350px;
  height: 100%;
  max-width: 100%;
}
@media all and (min-width: 500px) {
  .offer-offer-for-business .content-wrapper .image-container img {
    max-height: 500px;
  }
}
@media all and (min-width: 1025px) {
  .offer-offer-for-business .content-wrapper .image-container img {
    max-height: 650px;
  }
}

.offer-section {
  scroll-margin-top: 90px;
  padding: 20px;
}
@media all and (min-width: 500px) {
  .offer-section {
    padding: 40px;
  }
}
@media all and (min-width: 1025px) {
  .offer-section {
    padding: 60px;
  }
}
.offer-section.orange {
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
}
.offer-section.orange .section-header {
  background-color: #e7401d;
}
.offer-section.orange .offer-container .offer-block h4 {
  background-color: var(--proart-main-color-background);
  color: hsl(0, 0%, 100%);
}
.offer-section.gray {
  background-color: var(--proart-gray-color);
  color: var(--proart-main-font-color);
}
.offer-section.gray .section-header {
  color: var(--proart-main-font-color);
}
.offer-section.gray .offer-container .offer-block h4 {
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
}
.offer-section.navy {
  background-color: var(--proart-main-color-background);
  color: hsl(0, 0%, 100%);
}
.offer-section.navy .section-header {
  color: hsl(0, 0%, 100%);
}
.offer-section.navy .offer-container .offer-block h4 {
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
}
.offer-section.white {
  background-color: var(--background-color-solid-alternate);
  color: var(--proart-main-font-color);
}
.offer-section.white .section-header {
  color: var(--proart-main-font-color);
}
.offer-section .section-header {
  background-color: transparent;
  letter-spacing: 1px;
  font-weight: 600;
}
.offer-section .main-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
.offer-section .main-container.reverse {
  flex-direction: row-reverse;
}
@media all and (min-width: 1025px) {
  .offer-section .main-container {
    gap: 40px;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
}
@media all and (min-width: 1280px) {
  .offer-section .main-container {
    align-items: flex-start;
    gap: 80px;
  }
}
.offer-section .main-container img {
  order: 2;
  flex: 1;
  max-width: 720px;
  width: 100%;
}
@media all and (min-width: 1025px) {
  .offer-section .main-container img {
    order: 1;
    width: 50%;
  }
}
.offer-section .main-container .content {
  flex: 1;
}
@media all and (min-width: 1025px) {
  .offer-section .main-container .content {
    min-width: 450px;
    order: 2;
  }
}
.offer-section .main-container .content p {
  text-align: left;
  font-size: 16px;
  line-height: 18px;
}
@media all and (min-width: 500px) {
  .offer-section .main-container .content p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media all and (min-width: 1025px) {
  .offer-section .main-container .content p {
    font-size: 24px;
    line-height: 28px;
  }
}
.offer-section h2.section-header {
  padding: 60px 20px;
}
.offer-section .offer-container {
  gap: 20px;
  flex-wrap: wrap;
}
.offer-section .offer-container .offer-block {
  flex: 1 1 100%;
  all: unset;
  cursor: pointer;
}
@media all and (min-width: 500px) {
  .offer-section .offer-container .offer-block {
    flex: 1 1 47%;
  }
}
@media all and (min-width: 1025px) {
  .offer-section .offer-container .offer-block {
    flex: 1;
  }
}
.offer-section .offer-container .offer-block img {
  display: block;
  width: 100%;
}
.offer-section .offer-container .offer-block h3 {
  line-height: 40px;
  font-size: 18px;
  text-align: center;
}

.ui-theme-changer .switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}
.ui-theme-changer .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4b5b73;
  transition: 0.4s;
  border-radius: 21px;
}
.ui-theme-changer .switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 18px;
  left: 5px;
  bottom: 3px;
  z-index: 2;
  background-color: #ededed;
  transition: 0.4s;
}
.ui-theme-changer .switch .sun svg {
  position: absolute;
  top: 2px;
  left: 26px;
  z-index: 1;
  width: 19px;
  height: 19px;
  animation: rotate 15s linear infinite;
}
.ui-theme-changer .switch .sun svg polygon {
  fill: #f6f6f6;
}
.ui-theme-changer .switch .moon svg {
  fill: #4b5b73;
  position: absolute;
  top: 4px;
  left: 6px;
  z-index: 1;
  width: 17px;
  height: 17px;
  animation: tilt 5s linear infinite;
}
.ui-theme-changer .switch .moon svg path {
  fill: #f6f6f6;
}
.ui-theme-changer .switch .input {
  opacity: 0;
  width: 0;
  height: 0;
}
.ui-theme-changer .switch .input:checked + .slider {
  background-color: #4b5b73;
}
.ui-theme-changer .switch .input:focus + .slider {
  box-shadow: 0 0 1px #4b5b73;
}
.ui-theme-changer .switch .input:checked + .slider:before {
  transform: translateX(22px);
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.material-video-container {
  padding: 120px 20px 20px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 100%;
}
@media all and (min-width: 1025px) {
  .material-video-container {
    grid-template-columns: calc(72% - 30px) 28%;
  }
}

.material-proposed-video .material-thumbnail {
  display: grid;
  grid-template-columns: calc(40% - 10px) 60%;
  grid-gap: 10px;
  color: var(--proart-main-font-color);
  padding: 0.5rem 0;
  border-radius: 10px;
}
@media all and (min-width: 1025px) {
  .material-proposed-video .material-thumbnail {
    padding: 0.5rem;
  }
}
.material-proposed-video .material-thumbnail:hover {
  text-decoration: none;
  background-color: var(--proart-gray-color);
}
.material-proposed-video .material-thumbnail figure {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: inherit;
}
.material-proposed-video .material-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: contain;
  object-position: top;
}
.material-proposed-video .material-thumbnail .content {
  padding: 6px;
}
.material-proposed-video .material-thumbnail .content h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  min-width: 0;
  width: 100%;
}
.material-proposed-video .material-thumbnail .content p {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
}

.material-video {
  width: 100%;
}
.material-video .video-container {
  aspect-ratio: 16/9;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.material-video .video-container:hover .fullscreen-btn {
  opacity: 1;
}
.material-video .video-container .fullscreen-btn {
  position: absolute;
  top: 12%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  transition: opacity 0.12s linear;
  z-index: 2;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (min-width: 1025px) {
  .material-video .video-container .fullscreen-btn {
    top: 10%;
    font-size: 18px;
  }
}
.material-video .video-container .fullscreen-btn span {
  color: hsl(0, 0%, 100%);
  padding: 5px 10px;
  z-index: 1;
}
@media all and (min-width: 1025px) {
  .material-video .video-container .fullscreen-btn span {
    padding: 10px 20px;
  }
}
.material-video .video-container .fullscreen-btn .bgc {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e7401d;
  border-radius: 2px;
  opacity: 1;
}
.material-video .video-container #ad-video {
  position: relative;
}
.material-video .video-container #ad-video::before {
  content: "Reklama";
  padding: 0.2rem;
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
  font-size: 16px;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.material-video .video-container #ad-video.ended::before {
  content: "Wczytywanie video...";
}
.material-video .video-container video,
.material-video .video-container iframe {
  width: 100%;
  height: 100%;
}
.material-video .video-container ::-webkit-media-controls-fullscreen-button {
  display: none;
}
.material-video .video-container .video-watermark {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 22px;
  opacity: 0.5;
}
.material-video .content {
  padding: 10px 0;
}
@media all and (min-width: 1025px) {
  .material-video .content {
    padding: 10px;
  }
}
.material-video .content .title-section {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid var(--proart-gray-color);
  padding-bottom: 10px;
  margin-bottom: 10px;
  align-items: flex-start;
  flex-direction: column;
}
@media all and (min-width: 500px) {
  .material-video .content .title-section {
    align-items: flex-end;
    flex-direction: row;
  }
}
.material-video .content .title-section .title {
  text-align: left;
  margin: 0;
  line-height: normal;
  font-size: 28px;
}
@media all and (min-width: 500px) {
  .material-video .content .title-section .title {
    font-size: 30px;
  }
}
.material-video .content .title-section .material-symbols-outlined {
  font-size: 18px;
}
.material-video .content .title-section button {
  border: unset;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 16px;
  color: var(--font-color);
  padding: 6px 14px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  background-color: var(--proart-gray-color);
  will-change: background-color;
  transition: background-color 0.15s linear;
}
.material-video .content .title-section button:hover {
  background-color: hsl(0, 0%, 50%);
}
.material-video .content .description-container {
  background-color: var(--proart-gray-color);
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
}
.material-video .content .description-container h4 {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
}
.material-video .content .description-container h4 .views {
  margin-right: 6px;
}
.material-video .content .description-container .description {
  margin: 0;
}
@media all and (min-width: 500px) {
  .material-video .content .description-container .description {
    font-size: 18px;
  }
}
.material-video .link-container {
  text-align: center;
  margin-top: 20px;
}
.material-video .link-container a {
  display: inline-block;
  padding: 5px;
}
.material-video .material-videos-section {
  padding-top: 0;
}

.next-prev-video {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.next-prev-video > * {
  width: 100%;
}
@media all and (min-width: 1025px) {
  .next-prev-video {
    gap: 100px;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
  .next-prev-video > * {
    width: calc(50% - 50px);
  }
}

.next-prev-video__anchor {
  color: var(--proart-main-font-color);
  display: inline-block;
  max-width: 100%;
}
.next-prev-video__anchor:hover {
  text-decoration: none;
  color: #e7401d;
}
.next-prev-video__anchor > * {
  display: block;
  width: 100%;
}

@media all and (min-width: 1025px) {
  .next-prev-video__next {
    text-align: end;
    margin-left: auto;
  }
}

.next-prev-video__show-episodes {
  color: var(--proart-main-font-color);
}
@media all and (min-width: 1025px) {
  .next-prev-video__show-episodes {
    text-align: center;
    margin-top: auto;
  }
}
.next-prev-video__show-episodes:hover {
  text-decoration: none;
  color: #e7401d;
}

.next-prev-video__prefix {
  font-size: 16px;
}

.next-prev-video__title {
  font-size: 18px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media all and (min-width: 1025px) {
  .next-prev-video__title {
    font-size: 24px;
  }
}

.program-tv-slider {
  min-height: 80px;
  background-color: var(--proart-main-color-background);
}
@media all and (min-width: 1025px) {
  .program-tv-slider {
    min-height: 100px;
  }
}
.program-tv-slider .swiper .swiper-slide {
  position: relative;
}
.program-tv-slider .swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 420px;
  object-position: center;
  object-fit: cover;
  filter: brightness(0.65);
}
@media all and (min-width: 500px) {
  .program-tv-slider .swiper .swiper-slide img {
    height: 500px;
  }
}
@media all and (min-width: 1025px) {
  .program-tv-slider .swiper .swiper-slide img {
    height: auto;
    aspect-ratio: 16/9;
    min-height: 700px;
  }
}
.program-tv-slider .swiper .swiper-slide .content {
  position: absolute;
  top: 0;
  color: hsl(0, 0%, 100%);
  padding: 120px 10px 10px;
}
@media all and (min-width: 500px) {
  .program-tv-slider .swiper .swiper-slide .content {
    padding: 150px 20px 10px;
  }
}
@media all and (min-width: 1025px) {
  .program-tv-slider .swiper .swiper-slide .content {
    text-shadow: none;
    padding: 180px 50px 10px;
  }
}
@media all and (min-width: 1280px) {
  .program-tv-slider .swiper .swiper-slide .content {
    text-shadow: none;
    padding: 220px 100px 10px;
  }
}
.program-tv-slider .swiper .swiper-slide .content h2 {
  text-align: left;
  margin-bottom: 12px;
  letter-spacing: 2px;
  text-shadow: 1px 1px 2px black;
}
.program-tv-slider .swiper .swiper-slide .content h2 span {
  display: block;
}
.program-tv-slider .swiper .swiper-slide .content h2 span.title {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
}
@media all and (min-width: 500px) {
  .program-tv-slider .swiper .swiper-slide .content h2 span.title {
    font-size: 50px;
    line-height: 50px;
  }
}
@media all and (min-width: 1025px) {
  .program-tv-slider .swiper .swiper-slide .content h2 span.title {
    font-weight: 900;
    font-size: 72px;
    line-height: 72px;
  }
}
.program-tv-slider .swiper .swiper-slide .content h2 span.subtitle {
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 2px;
}
@media all and (min-width: 500px) {
  .program-tv-slider .swiper .swiper-slide .content h2 span.subtitle {
    font-size: 40px;
    line-height: 40px;
  }
}
@media all and (min-width: 1025px) {
  .program-tv-slider .swiper .swiper-slide .content h2 span.subtitle {
    font-size: 50px;
    line-height: 50px;
  }
}
.program-tv-slider .swiper .swiper-slide .content h3 {
  text-align: left;
  font-size: 24px;
  margin: 0;
  text-shadow: 1px 1px 2px black;
}
.program-tv-slider .swiper .swiper-slide .content .premier {
  font-size: 16px;
  text-transform: uppercase;
  background-color: var(--success-color);
  padding: 1px 10px;
  width: fit-content;
  border-radius: 10px;
  margin: 12px 0;
}
.program-tv-slider .swiper .swiper-slide .content .description {
  text-shadow: 1px 1px 2px black;
  margin: 0;
  max-width: 720px;
}

.program-tv-days {
  background-color: var(--proart-gray-color);
}
.program-tv-days .container {
  padding: 20px;
}
@media all and (min-width: 1280px) {
  .program-tv-days .container {
    padding: 40px;
  }
}
.program-tv-days .container .days {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
@media all and (min-width: 500px) {
  .program-tv-days .container .days {
    gap: 20px;
  }
}
@media all and (min-width: 1280px) {
  .program-tv-days .container .days {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 40px;
  }
}
.program-tv-days .container .days .day {
  background-color: #e7401d;
  padding: 8px;
  width: 100%;
  cursor: pointer;
}
@media all and (min-width: 500px) {
  .program-tv-days .container .days .day {
    width: 200px;
    padding: 14px 10px;
  }
}
.program-tv-days .container .days .day.active {
  background-color: var(--proart-main-color-background);
}
.program-tv-days .container .days .day h3,
.program-tv-days .container .days .day h4 {
  color: hsl(0, 0%, 100%);
}
.program-tv-days .container .days .day h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.program-tv-days .container .days .day h4 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.program-tv-days .container .program-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 1000px;
  overflow-y: auto;
}
.program-tv-days .container .program-container .program {
  display: flex;
  min-height: 110px;
  background-color: var(--background-color-solid);
  text-decoration: none;
}
.program-tv-days .container .program-container .program .time {
  padding: 10px 10px;
  color: var(--proart-main-font-color);
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 70px;
}
@media all and (min-width: 500px) {
  .program-tv-days .container .program-container .program .time {
    width: 90px;
  }
}
@media all and (min-width: 1025px) {
  .program-tv-days .container .program-container .program .time {
    padding: 10px 20px;
    width: 110px;
    font-size: 24px;
  }
}
.program-tv-days .container .program-container .program .time.current {
  color: hsl(0, 0%, 100%);
  background-color: #e7401d;
}
.program-tv-days .container .program-container .program .time .ongoing {
  text-transform: uppercase;
}
.program-tv-days .container .program-container .program .content {
  width: 100%;
  padding: 10px;
}
@media all and (min-width: 1025px) {
  .program-tv-days .container .program-container .program .content {
    padding: 10px 20px;
  }
}
.program-tv-days .container .program-container .program .content h3 {
  color: var(--proart-main-font-color);
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 1px;
  letter-spacing: 0;
  font-weight: 700;
  font-size: 18px;
}
@media all and (min-width: 1025px) {
  .program-tv-days .container .program-container .program .content h3 {
    font-size: 24px;
  }
}
.program-tv-days .container .program-container .program .content p {
  color: var(--proart-main-font-color);
  font-size: 16px;
  margin: 0;
}
@media all and (min-width: 1025px) {
  .program-tv-days .container .program-container .program .content p {
    font-size: 18px;
  }
}
.program-tv-days .container .program-container .program .content .status {
  font-size: 12px;
  text-transform: uppercase;
  padding: 0 3px;
  text-align: center;
  color: hsl(0, 0%, 100%);
  width: 100px;
  border-radius: 10px;
  margin-top: 14px;
}
.program-tv-days .container .program-container .program .content .status.live {
  background-color: #e7401d;
}
.program-tv-days .container .program-container .program .content .status.repeat {
  background-color: #e98604;
}
.program-tv-days .container .program-container .program .content .status.premier {
  background-color: var(--success-color);
}

.about-us__banner {
  overflow: hidden;
  width: 100%;
  padding-top: 43.75%;
  height: 0;
  position: relative;
  margin-top: 80px;
}
@media all and (min-width: 500px) {
  .about-us__banner {
    margin-top: 0;
  }
}

.about-us__banner-title {
  visibility: hidden;
  z-index: -1;
  position: relative;
}

.about-us__banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  object-fit: cover;
  object-position: center;
}

.about-us__main-section-img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
  max-height: 500px;
}
.about-us__main-section-content {
  max-width: 1280px;
  margin: 0 auto 20px;
  padding: 20px 20px 0 20px;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  min-width: 0;
  width: 100%;
}
.about-us__main-section-button-container {
  display: flex;
  justify-content: center;
  margin: 0 auto 20px;
  padding: 0 20px;
}

.about-us__skeleton {
  padding-left: 20px;
  padding-right: 20px;
}

.about-us__skeleton-header {
  padding-top: 65px;
  padding-bottom: 65px;
}
.about-us__skeleton-header > div {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.about-us__skeleton-body {
  padding-top: 70px;
  padding-bottom: 70px;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
}
@media all and (min-width: 1025px) {
  .about-us__skeleton-body {
    grid-template-columns: calc(60% - 100px) 40%;
    grid-gap: 60px;
  }
}
@media all and (min-width: 1280px) {
  .about-us__skeleton-body {
    grid-gap: 100px;
  }
}
.about-us__skeleton-body .skeleton.skeleton-pharagraph:first-of-type {
  width: 30%;
}

.about-us__section {
  overflow: hidden;
}
.about-us__section:nth-of-type(even) .section-header {
  background-color: #e7401d;
}
.about-us__section .content-wrapper {
  padding: 20px 20px 0;
}
.about-us__section .about-us__section-grid:not(.single):first-of-type, .about-us__section .about-us__section-grid:not(.single):nth-of-type(3n + 1) {
  background-color: var(--proart-gray-color);
}
.about-us__section .about-us__section-grid:not(.single):nth-of-type(3n + 2) {
  background-color: var(--background-color-solid-alternate);
}
@media all and (min-width: 500px) {
  .about-us__section .about-us__section-grid:not(.single):nth-of-type(3n + 2) .about-us__item-picture {
    order: -1;
  }
}
.about-us__section .about-us__section-grid:not(.single):nth-of-type(3n) {
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
}
.about-us__section .about-us__section-grid:not(.single):nth-of-type(3n) .about-us__item-caption a.button.orange {
  background-color: var(--proart-main-color-background);
  color: hsl(0, 0%, 100%);
}
.about-us__section .about-us__section-grid:not(.single):nth-of-type(3n) .about-us__item-caption a.button.orange:hover {
  background-color: var(--proart-main-color-background);
}

.about-us__section-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  grid-gap: 50px;
  color: var(--proart-main-font-color);
}
.about-us__section-grid.single .about-us__item-caption h2 br {
  display: none;
}
@media all and (min-width: 1025px) {
  .about-us__section-grid {
    grid-template-columns: calc(50% - 50px) calc(50% - 50px);
    grid-gap: 80px;
  }
  .about-us__section-grid.single {
    grid-template-columns: 65%;
    grid-gap: 0px;
  }
  .about-us__section-grid.single .about-us__section-grid-item {
    grid-template-columns: 60% 40%;
    gap: 1rem;
  }
  .about-us__section-grid.single .about-us__item-caption {
    margin-top: 80px;
    height: calc(100% - 80px);
  }
}

.about-us__section-grid-item {
  display: grid;
  grid-template-columns: 100%;
}
@media all and (min-width: 500px) {
  .about-us__section-grid-item {
    grid-template-columns: 50% 50%;
    gap: 1rem;
  }
}

.about-us__item-caption {
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.about-us__item-caption h2 {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 24px;
}
@media all and (min-width: 500px) {
  .about-us__item-caption h2 {
    text-align: start;
    font-size: 28px;
    line-height: 28px;
  }
}
@media all and (min-width: 1025px) {
  .about-us__item-caption h2 {
    font-size: 36px;
    line-height: 36px;
  }
}
.about-us__item-caption div {
  text-align: justify;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
}
@media all and (min-width: 1025px) {
  .about-us__item-caption div {
    font-size: 18px;
    line-height: 24px;
  }
}
.about-us__item-caption div.about-us__item-description {
  margin-bottom: 8px;
  text-align: left;
}
.about-us__item-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  gap: 10px;
}
@media all and (min-width: 1025px) {
  .about-us__item-button {
    order: unset;
  }
}
.about-us__item-button .button {
  font-size: 16px;
  text-align: left;
  padding: 0.5rem 1rem;
  text-transform: none;
  width: 100%;
}
@media all and (min-width: 500px) {
  .about-us__item-button .button {
    width: fit-content;
  }
}

.about-us__item-picture {
  order: -1;
}
@media all and (min-width: 1025px) {
  .about-us__item-picture {
    order: unset;
  }
}
.about-us__item-picture img {
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  object-position: bottom;
  width: 100%;
  height: 100%;
  display: block;
}
@media all and (min-width: 500px) {
  .about-us__item-picture img {
    max-width: unset;
    margin-left: 0;
    margin-right: 0;
  }
}

.material-videos-section {
  display: flex;
  gap: 20px;
  padding-top: 120px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 120px 10px 20px;
}
.material-videos-section .ui-pagination {
  width: 100%;
}
.material-videos-section .filters-box {
  width: 100%;
}
@media all and (min-width: 500px) {
  .material-videos-section .filters-box {
    flex-basis: 180px;
  }
}
@media all and (min-width: 1025px) {
  .material-videos-section .filters-box {
    flex-basis: 220px;
  }
}
.material-videos-section .filters-box .reset-filters {
  font-size: 14px;
  margin: 1rem 1rem 0 1rem;
  display: inline-block;
}
.material-videos-section .filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  flex-basis: 100%;
}
@media all and (min-width: 500px) {
  .material-videos-section .filters {
    justify-content: flex-start;
    flex-direction: column;
  }
}
.material-videos-section .filters .ui-select-box {
  flex: 1;
  width: 180px;
}
@media all and (min-width: 500px) {
  .material-videos-section .filters .ui-select-box {
    flex: initial;
  }
}
.material-videos-section .filters input,
.material-videos-section .filters .ui-sort-select {
  flex: 1;
  min-width: 140px;
}
@media all and (min-width: 500px) {
  .material-videos-section .filters input,
  .material-videos-section .filters .ui-sort-select {
    flex: initial;
  }
}
.material-videos-section .filters h4 {
  color: var(--proart-main-font-color);
  text-align: left;
  width: 100%;
  padding-left: 20px;
}
.material-videos-section .materials {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 900px;
}
.material-videos-section .materials .material {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  text-decoration: none;
  color: var(--proart-main-font-color);
  background-color: var(--background-color-solid);
  padding: 16px;
  border-radius: 20px;
  width: 100%;
  flex-direction: column;
}
@media all and (min-width: 500px) {
  .material-videos-section .materials .material {
    max-width: 900px;
    flex-direction: row;
  }
}
@media all and (min-width: 1025px) {
  .material-videos-section .materials .material {
    gap: 20px;
  }
}
.material-videos-section .materials .material:hover {
  background-color: var(--proart-gray-color);
}
.material-videos-section .materials .material-image {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}
@media all and (min-width: 500px) {
  .material-videos-section .materials .material-image {
    width: 50%;
    max-width: 260px;
  }
}
@media all and (min-width: 1025px) {
  .material-videos-section .materials .material-image {
    width: 100%;
    max-width: 360px;
  }
}
.material-videos-section .materials .material figure {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.material-videos-section .materials .material figure img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.material-videos-section .materials .material .content h3 {
  text-align: left;
  font-size: 18px;
  margin-bottom: 5px;
  color: var(--proart-main-font-color);
}
.material-videos-section .materials .material .content .publishedAt {
  color: hsl(0, 0%, 50%);
  font-size: 16px;
}
.material-videos-section .materials .material .content p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 16px;
  color: hsl(0, 0%, 50%);
}

.episodes {
  padding-top: 65px;
  padding-bottom: 50px;
  background-color: var(--proart-gray-color);
  color: var(--proart-main-font-color);
}
.episodes .content-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media all and (min-width: 1025px) {
  .episodes .content-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
}
.episodes.bg-primary {
  background-color: var(--proart-main-color-background);
  color: hsl(0, 0%, 100%);
}
.episodes.bg-primary .swiper-button-next,
.episodes.bg-primary .swiper-button-prev {
  color: var(--proart-gray-color);
}

.episodes__header {
  margin: 0;
  padding-bottom: 65px;
}

.episodes__title {
  margin: 0;
}

.episodes__swiper {
  padding-bottom: 30px;
}
.episodes__swiper:not(.swiper-initialized) .swiper-wrapper {
  display: flex;
  gap: 45px;
}
.episodes__swiper:not(.swiper-initialized) .episodes-swiper__item {
  width: 25%;
  flex-shrink: 1;
}
.episodes__swiper .swiper-button-next,
.episodes__swiper .swiper-button-prev {
  transform: translateY(-50%);
  width: 44px;
}
.episodes__swiper .swiper-button-next::after,
.episodes__swiper .swiper-button-prev::after {
  width: 44px;
}
.episodes__swiper .swiper-button-next.swiper-button-disabled,
.episodes__swiper .swiper-button-prev.swiper-button-disabled {
  pointer-events: all;
}
.episodes__swiper .swiper-button-next::after {
  content: url("/data/icons/left.svg");
}
.episodes__swiper .swiper-button-prev::after {
  content: url("/data/icons/right.svg");
}
.episodes__swiper .swiper-scrollbar.swiper-scrollbar-horizontal {
  left: 0;
  right: 0;
  width: 100%;
  height: 12px;
}

.episodes-swiper__item {
  display: block;
  text-decoration: none;
  color: inherit;
}
.episodes-swiper__item:hover {
  text-decoration: none;
  color: inherit;
}

.episodes-swiper__item-figure {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
  overflow: hidden;
  display: block;
}
.episodes-swiper__item-figure .skeleton,
.episodes-swiper__item-figure img:not(.episodes-swiper__series-thumbnail) {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.episodes-swiper__item-figure .episodes-swiper__series-thumbnail {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.episodes-swiper__item-header {
  margin-bottom: 10px;
}

.episodes-swiper__item-title {
  margin-bottom: 0;
  margin-top: 20px;
  text-align: start;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.episodes-swiper__item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.1rem;
  max-height: 4.4rem;
  margin-bottom: 15px;
}

.episodes-skeleton .episodes-skeleton__title {
  margin-top: 20px;
}

.episodes-group {
  scroll-margin-top: 90px;
}
@media all and (min-width: 1025px) {
  .episodes-group.odd .episodes-group__container {
    flex-direction: row;
  }
}
.episodes-group.lightorange {
  background-color: #e98604;
}
.episodes-group.primary {
  background-color: var(--proart-main-color-background);
}
.episodes-group.orange {
  background-color: #e7401d;
}
.episodes-group.green {
  background-color: #0a8e36;
}

.episodes-group__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
@media all and (min-width: 1025px) {
  .episodes-group__container {
    flex-direction: row-reverse;
  }
}

.episodes-group__picture-col {
  width: 100%;
  aspect-ratio: 16/9;
}
@media all and (min-width: 1025px) {
  .episodes-group__picture-col {
    width: 50%;
  }
}
@media all and (min-width: 1280px) {
  .episodes-group__picture-col {
    width: 60%;
  }
}
.episodes-group__picture-col img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/9;
  display: block;
}

.episodes-group__content-col {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media all and (min-width: 1025px) {
  .episodes-group__content-col {
    padding: 20px 50px;
    width: 50%;
  }
}
@media all and (min-width: 1280px) {
  .episodes-group__content-col {
    width: 40%;
  }
}

.episodes-group__content {
  text-align: start;
  color: hsl(0, 0%, 100%);
}

.episodes-group__title, .episodes__promoted-title {
  line-height: normal;
  font-weight: 900;
  text-align: inherit;
  margin-bottom: 30px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 28px;
}
@media all and (min-width: 500px) {
  .episodes-group__title, .episodes__promoted-title {
    font-size: 36px;
  }
}
@media all and (min-width: 1025px) {
  .episodes-group__title, .episodes__promoted-title {
    font-size: 54px;
    margin-bottom: 20px;
  }
}

.episodes-group__description, .episodes__promoted-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
}
@media all and (min-width: 500px) {
  .episodes-group__description, .episodes__promoted-description {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}
@media all and (min-width: 1025px) {
  .episodes-group__description, .episodes__promoted-description {
    -webkit-line-clamp: 10;
    line-clamp: 10;
  }
}

.episodes-group__cta {
  margin-top: auto;
  text-align: start;
}
@media all and (min-width: 1025px) {
  .episodes-group__cta {
    text-align: center;
  }
}
.episodes-group__cta .button:after {
  content: ">>";
  margin-left: 0.5rem;
  display: inline-block;
}

.episodes__promoted {
  background-color: hsl(0, 0%, 0%);
  position: relative;
  min-height: 45vh;
  overflow: hidden;
  width: 100%;
}
@media all and (min-width: 500px) {
  .episodes__promoted {
    min-height: 400px;
    aspect-ratio: 16/9;
  }
}
@media all and (min-width: 1025px) {
  .episodes__promoted {
    height: auto;
    min-height: auto;
  }
}
.episodes__promoted-header {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding-right: 20px;
  z-index: 3;
  max-width: calc(100% - 20px);
}
@media all and (min-width: 500px) {
  .episodes__promoted-header {
    bottom: auto;
    top: 50%;
    padding-left: 20px;
    transform: translateY(-20%);
  }
}
@media all and (min-width: 1025px) {
  .episodes__promoted-header {
    padding-left: 80px;
    transform: translateY(-30%);
  }
}
.episodes__promoted-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0px 0px 2px hsl(0, 0%, 0%);
  color: hsl(0, 0%, 100%);
  margin: 0;
  line-height: 32px;
}
@media all and (min-width: 500px) {
  .episodes__promoted-title {
    line-height: 58px;
  }
}
@media all and (min-width: 1025px) {
  .episodes__promoted-title {
    line-height: 62px;
    margin-bottom: 16px;
  }
}
.episodes__promoted-date {
  color: #e7401d;
  text-shadow: 0px 0px 2px hsl(0, 0%, 0%);
  font-size: 18px;
  font-weight: bold;
}
@media all and (min-width: 500px) {
  .episodes__promoted-date {
    font-size: 24px;
  }
}
@media all and (min-width: 1025px) {
  .episodes__promoted-date {
    font-size: 28px;
  }
}
.episodes__promoted-description {
  color: hsl(0, 0%, 100%);
  width: 100%;
  margin-top: 10px;
  text-shadow: 0px 0px 2px hsl(0, 0%, 0%);
}
@media all and (min-width: 500px) {
  .episodes__promoted-description {
    width: 42%;
    margin-top: 12px;
    min-width: 320px;
  }
}
@media all and (min-width: 1025px) {
  .episodes__promoted-description {
    min-width: 400px;
  }
}
.episodes__promoted-live {
  margin-top: 20px;
}
.episodes__promoted-live svg {
  width: 144px;
  height: auto;
}
.episodes__promoted-live svg .cls-1 {
  fill: hsl(0, 0%, 100%);
}
.episodes__promoted-live svg .cls-2 {
  fill: #e52521;
}
.episodes__promoted-live svg .cls-3 {
  fill: #f39323;
}
.episodes__promoted-button {
  display: block;
  margin-top: 10px;
}
.episodes__promoted-button svg {
  width: 144px;
  height: auto;
}
.episodes__promoted-button svg .cls-1 {
  fill: hsl(0, 0%, 100%);
}
.episodes__promoted-button svg .cls-2 {
  fill: #e52521;
}
.episodes__promoted-button svg .cls-3 {
  fill: #4b5b73;
}
@media all and (min-width: 500px) {
  .episodes__promoted-button {
    margin-top: 24px;
  }
}
@media all and (min-width: 1025px) {
  .episodes__promoted-button {
    margin-top: 36px;
  }
}
.episodes__promoted-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 2;
}
.episodes__promoted video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  background-color: hsl(0, 0%, 0%);
  min-width: 100%;
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.contact__banner {
  overflow: hidden;
  width: 100%;
  padding-top: 43.75%;
  height: 0;
  position: relative;
  margin-top: 80px;
}
@media all and (min-width: 500px) {
  .contact__banner {
    margin-top: 0;
  }
}
.contact__banner-title {
  visibility: hidden;
  z-index: -1;
  position: relative;
}
.contact__banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  object-fit: cover;
  object-position: center;
}

.contact__skeleton {
  padding-left: 20px;
  padding-right: 20px;
}
.contact__skeleton-header {
  padding-top: 65px;
  padding-bottom: 65px;
}
.contact__skeleton-header > div {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.contact__skeleton-body {
  padding-top: 70px;
  padding-bottom: 70px;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
}
@media all and (min-width: 1025px) {
  .contact__skeleton-body {
    grid-template-columns: calc(60% - 100px) 40%;
    grid-gap: 60px;
  }
}
@media all and (min-width: 1280px) {
  .contact__skeleton-body {
    grid-gap: 100px;
  }
}
.contact__skeleton-body .skeleton.skeleton-pharagraph:first-of-type {
  width: 30%;
}

.contact__section:nth-of-type(even) .section-header {
  background-color: #e7401d;
}
.contact__section .content-wrapper {
  padding: 75px 20px 0 20px;
}
.contact__section .contact__section-grid:not(.single):first-of-type, .contact__section .contact__section-grid:not(.single):nth-of-type(3n + 1) {
  background-color: var(--proart-gray-color);
}
.contact__section .contact__section-grid:not(.single):nth-of-type(3n + 2) {
  background-color: var(--background-color-solid-alternate);
}
@media all and (min-width: 500px) {
  .contact__section .contact__section-grid:not(.single):nth-of-type(3n + 2) .contact__item-picture {
    order: -1;
  }
}
.contact__section .contact__section-grid:not(.single):nth-of-type(3n) {
  background-color: #e7401d;
  color: hsl(0, 0%, 100%);
}
.contact__section-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  grid-gap: 50px;
  color: var(--proart-main-font-color);
}
.contact__section-grid.single .contact__item-caption h2 br {
  display: none;
}
@media all and (min-width: 1025px) {
  .contact__section-grid {
    grid-template-columns: calc(50% - 50px) calc(50% - 50px);
    grid-gap: 100px;
  }
  .contact__section-grid.single {
    grid-template-columns: 65%;
    grid-gap: 0px;
  }
  .contact__section-grid.single .contact__section-grid-item {
    grid-template-columns: 60% 40%;
    gap: 1rem;
  }
  .contact__section-grid.single .contact__item-caption {
    margin-top: 100px;
    height: calc(100% - 100px);
  }
}
.contact__section-grid-item {
  display: grid;
  grid-template-columns: 100%;
}
@media all and (min-width: 500px) {
  .contact__section-grid-item {
    grid-template-columns: 50% 50%;
  }
}

.contact__item-caption {
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.contact__item-caption h2 {
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 24px;
}
@media all and (min-width: 500px) {
  .contact__item-caption h2 {
    text-align: start;
    font-size: 28px;
    line-height: 28px;
  }
}
@media all and (min-width: 1025px) {
  .contact__item-caption h2 {
    margin-bottom: 70px;
    font-size: 36px;
    line-height: 36px;
  }
}
.contact__item-caption div {
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  text-align: justify;
}
@media all and (min-width: 1025px) {
  .contact__item-caption div {
    font-size: 18px;
    line-height: 24px;
  }
}
.contact__item-caption div.contact__item-description {
  margin-bottom: 8px;
  text-align: left;
}
.contact__item-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  gap: 10px;
}
@media all and (min-width: 1025px) {
  .contact__item-button {
    order: unset;
  }
}
.contact__item-button .button {
  font-size: 16px;
  text-align: left;
  padding: 0.5rem 1rem;
  text-transform: none;
  width: 100%;
}
@media all and (min-width: 500px) {
  .contact__item-button .button {
    width: fit-content;
  }
}
.contact__item-picture {
  order: -1;
}
@media all and (min-width: 1025px) {
  .contact__item-picture {
    order: unset;
  }
}
.contact__item-picture img {
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  object-position: bottom;
  width: 100%;
  height: 100%;
  display: block;
}
@media all and (min-width: 500px) {
  .contact__item-picture img {
    max-width: unset;
    margin-left: 0;
    margin-right: 0;
  }
}

.contact-data__grid.content-wrapper {
  display: grid;
  grid-gap: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
  grid-template-columns: 100%;
}
@media all and (min-width: 1025px) {
  .contact-data__grid.content-wrapper {
    grid-template-columns: calc(50% - 50px) 50%;
  }
}
.contact-data__info .footer-contact {
  margin-top: 24px;
}
.contact-data__info .footer-contact-title {
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--proart-main-font-color);
  margin-bottom: 40px;
}
@media all and (min-width: 1025px) {
  .contact-data__info .footer-contact-title {
    margin-bottom: 70px;
  }
}
.contact-data__info .footer-email {
  margin-top: 24px;
}
.contact-data__info .footer-email .contact-message-popup-button {
  border: 0;
  background-color: transparent;
  color: var(--font-color);
  box-shadow: none;
  font-size: 16px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}
@media all and (min-width: 1025px) {
  .contact-data__info .footer-email .contact-message-popup-button {
    font-size: 18px;
  }
}
.contact-data__info .footer-email .contact-message-popup-button:hover {
  text-decoration: underline;
}
.contact-data__map iframe {
  border: 1px solid var(--proart-gray-color);
}

.contact__bottom-banner img {
  width: 100%;
}

.static-page-main {
  display: block;
  margin: 100px auto 0px auto;
  padding: 30px 20px;
}
@media all and (min-width: 1025px) {
  .static-page-main {
    padding: 40px 20px;
    min-height: 60vh;
  }
}
.static-page-main * {
  box-sizing: border-box;
}
.static-page-main ul,
.static-page-main ol {
  margin: 20px 0;
  padding-left: 30px;
}
@media all and (min-width: 1025px) {
  .static-page-main ul,
  .static-page-main ol {
    padding-left: 40px;
  }
}
.static-page-main p,
.static-page-main ul,
.static-page-main ol,
.static-page-main figure,
.static-page-main div,
.static-page-main table {
  margin-bottom: 20px;
  line-height: 150%;
}
.static-page-main img {
  max-width: 100%;
  border-radius: 12px;
}
.static-page-main table {
  border-collapse: collapse;
}
.static-page-main table th,
.static-page-main table td {
  padding: 0.5rem 1rem;
  border: 1px solid var(--background-color-gray);
}
.static-page-main table tbody tr:nth-child(odd) {
  background-color: var(--background-color-gray);
}

.comments-rss-form {
  padding: 120px 20px 20px;
  max-width: 640px;
  margin: 0 auto;
}
.comments-rss-form .uploader-container {
  margin-bottom: 10px;
}
.comments-rss-form .uploader-container label {
  display: inline-block;
  margin-bottom: 2px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  line-height: 18px;
}
@media all and (min-width: 1025px) {
  :root {
    font-size: 18px;
    line-height: 24px;
  }
}

body {
  background-color: var(--background-color-solid);
  font-family: "Montserrat", sans-serif;
  color: var(--font-color);
  min-height: 100vh;
  min-width: 320px;
  position: relative;
}
body h2 {
  text-align: center;
  letter-spacing: 1px;
  font-size: 24px;
  margin-bottom: 24px;
}
body h3 {
  text-align: center;
  letter-spacing: 1px;
  font-size: 18px;
  margin-bottom: 18px;
}
body p {
  margin-bottom: 1rem;
}
body a {
  color: var(--main-color);
  text-decoration: none;
  cursor: pointer;
}
body a:hover {
  color: var(--main-color-accent);
  text-decoration: underline;
}
body .hidden {
  display: none !important;
}
body .clear {
  clear: both;
}
body input:-webkit-autofill {
  background-clip: text;
  -webkit-text-fill-color: var(--font-color);
}
body .grecaptcha-badge {
  visibility: hidden;
}
body .ad-container {
  min-height: 280px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

html {
  scroll-behavior: smooth;
}