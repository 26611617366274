.contact__banner {
    overflow: hidden;
    width: 100%;
    padding-top: 43.75%;
    height: 0;
    position: relative;
    margin-top: 80px;

    @include rwd(tablet) {
        margin-top: 0;
    }

    &-title {
        visibility: hidden;
        z-index: -1;
        position: relative;
    }

    &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        object-fit: cover;
        object-position: center;
    }
}
