.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($blackColor, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .wrapper {
        position: relative;
        max-width: 100%;

        img {
            padding: 20px;
            pointer-events: none;
            max-width: 100%;
            max-height: 100vh;
            object-fit: contain;
        }

        button {
            position: absolute;
            top: 36px;
            right: 36px;
            font-size: $fontSizeXXL;
            background-color: transparent;
            border: none;
            color: $whiteColor;
            text-shadow: 0 0 5px $blackColor;
            cursor: pointer;
        }
    }
}
