.offer-offer-for-business {
    $fontSizeJobPosition: 18px;

    .content-wrapper {
        background-color: $backgroundColorSolidAlternate;
        padding-top: 36px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 1rem;
        padding-right: 1rem;

        @include rwd(tablet) {
            padding-left: 2rem;
            padding-top: 60px;
        }

        @include rwd(laptop) {
            gap: 10px;
            padding-left: 60px;
        }

        &.no-content {
            padding: 0;

            .image-container {
                flex: 1;
                aspect-ratio: 16 / 9;

                img {
                    max-height: 100%;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .content {
            flex: 1 0 47%;
            padding: 10px 0;
            color: $proartMainFontColor;

            @include rwd(laptop) {
                max-width: 640px;
            }

            h3 {
                text-transform: uppercase;
                font-size: $fontSizeL;
                line-height: $fontSizeXL;
                text-align: left;

                @include rwd(tablet) {
                    font-size: $fontSizeXL;
                    line-height: $fontSizeXXL;
                }

                @include rwd(laptop) {
                    font-size: $fontSizeXXL;
                    line-height: $fontSizeXXXL;
                }
            }

            p {
                margin-bottom: 30px;
                text-align: left;
                font-size: $fontSizeM;
                line-height: $fontSizeL;

                @include rwd(tablet) {
                    font-size: $fontSizeL;
                    line-height: $fontSizeXL;
                }

                @include rwd(laptop) {
                    font-size: $fontSizeXL;
                    line-height: $fontSizeXXL;
                }
            }

            .sign {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .name {
                    font-size: $fontSizeL;
                    line-height: $fontSizeXL;
                    font-weight: 600;

                    @include rwd(laptop) {
                        font-size: $fontSizeXL;
                        line-height: $fontSizeXL;
                    }
                }

                .job-position {
                    font-size: $fontSizeM;

                    @include rwd(laptop) {
                        font-size: $fontSizeJobPosition;
                    }
                }
            }
        }

        .image-container {
            display: flex;
            justify-content: center;
            flex: 0 0 50%;

            img {
                max-height: 350px;
                height: 100%;
                max-width: 100%;

                @include rwd(tablet) {
                    max-height: 500px;
                }

                @include rwd(laptop) {
                    max-height: 650px;
                }
            }
        }
    }
}
