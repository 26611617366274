.about-us__skeleton {
    padding-left: 20px;
    padding-right: 20px;
}

.about-us__skeleton-header {
    padding-top: 65px;
    padding-bottom: 65px;

    & > div {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}

.about-us__skeleton-body {
    padding-top: 70px;
    padding-bottom: 70px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;

    @include rwd(laptop) {
        grid-template-columns: calc(60% - 100px) 40%;
        grid-gap: 60px;
    }

    @include rwd(desktop) {
        grid-gap: 100px;
    }

    .skeleton.skeleton-pharagraph {
        &:first-of-type {
            width: 30%;
        }
    }
}
