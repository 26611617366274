.ui-pagination {
    $sizeMobile: 35px;
    $sizeTablet: 45px;
    $transitionProperties: 0.15s linear;

    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 10px;

    div {
        &.page {
            width: $sizeMobile;
            height: $sizeMobile;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $proartMainBackgroundColor;
            color: $whiteColor;
            border-radius: calc($sizeMobile / 2);
            font-weight: 700;
            font-size: $fontSizeL;
            cursor: pointer;
            transition: background-color $transitionProperties;

            @include rwd(tablet) {
                border-radius: calc($sizeTablet / 2);
                width: $sizeTablet;
                height: $sizeTablet;
                font-size: $fontSizeXL;
            }

            &:hover,
            &.active {
                background-color: $proartSecondColor;
            }
        }

        &.dots {
            font-size: $fontSizeL;
            align-self: flex-end;
            font-weight: 700;
            letter-spacing: 1px;

            @include rwd(tablet) {
                letter-spacing: 2px;
                font-size: $fontSizeXL;
            }
        }
    }
}
