.static-page-main {
    display: block;
    margin: 100px auto 0px auto;
    padding: 30px 20px;

    @include rwd(laptop) {
        padding: 40px 20px;
        min-height: 60vh;
    }

    * {
        box-sizing: border-box;
    }
    
    ul,
    ol {
        margin: 20px 0;
        padding-left: 30px;

        @include rwd(laptop) {
            padding-left: 40px;
        }
    }

    p,
    ul,
    ol,
    figure,
    div,
    table {
        margin-bottom: 20px;
        line-height: 150%;
    }

    img {
        max-width: 100%;
        border-radius: 12px;
    }

    table {
        border-collapse: collapse;
        
        th,
        td {
            padding: .5rem 1rem;
            border: 1px solid $backgroundColorGray;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background-color: $backgroundColorGray;
                }
            }
        }
    }
}
