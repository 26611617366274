@use 'sass:map';

$lightThemeBackgroundColor: map.get($grayColors, 90);
$lightThemeBackgroundColorAlternate: map.get($grayColors, 95);
$lightThemeBackgroundColorSolid: map.get($grayColors, 100);
$lightThemeBackgroundColorGray: map.get($grayColors, 80);
$lightThemeFontColor: map.get($grayColors, 0);

$lightThemeNormalLightness: 600;
$lightThemeAccentLightness: $lightThemeNormalLightness + 100;
$lightThemeBackgroundLightness: 50;
$lightThemeProartMainLightness: 800;
$lightThemeProartGrayLightness: 100;

$lightThemeMainPalette: $blueColors;
$lightThemeErrorPalette: $redColors;
$lightThemeWarningPalette: $yellowColors;
$lightThemeSuccessPalette: $greenColors;
$lightThemeProartMainPalette: $proartMainColors;
$lightThemeProartGrayPalette: $proartGrayColors;

body {
    --background-color: #{$lightThemeBackgroundColor};
    --background-color-alternate: #{$lightThemeBackgroundColorAlternate};
    --background-color-solid: #{$lightThemeBackgroundColorSolid};
    --background-color-solid-alternate: #{$lightThemeBackgroundColorSolid};
    --background-color-solid-section-header: #{$lightThemeBackgroundColorSolid};
    --background-color-gray: #{$lightThemeBackgroundColorGray};
    --font-color: #{$lightThemeFontColor};

    --main-color: #{map.get($lightThemeMainPalette, $lightThemeNormalLightness)};
    --main-color-accent: #{map.get($lightThemeMainPalette, $lightThemeAccentLightness + 100)};
    --main-color-background: #{map.get($lightThemeMainPalette, $lightThemeBackgroundLightness)};

    --error-color: #{map.get($lightThemeErrorPalette, $lightThemeNormalLightness)};
    --error-color-accent: #{map.get($lightThemeErrorPalette, $lightThemeAccentLightness)};
    --error-color-background: #{map.get($lightThemeErrorPalette, $lightThemeBackgroundLightness)};

    --warning-color: #{map.get($lightThemeWarningPalette, $lightThemeNormalLightness)};
    --warning-color-accent: #{map.get($lightThemeWarningPalette, $lightThemeAccentLightness)};
    --warning-color-background: #{map.get($lightThemeWarningPalette, $lightThemeBackgroundLightness)};

    --success-color: #{map.get($lightThemeSuccessPalette, $lightThemeNormalLightness)};
    --success-color-accent: #{map.get($lightThemeSuccessPalette, $lightThemeAccentLightness)};
    --success-color-background: #{map.get($lightThemeSuccessPalette, $lightThemeBackgroundLightness)};

    --proart-main-font-color: #{map.get($lightThemeProartMainPalette, $lightThemeProartMainLightness)};
    --proart-main-color-background: #{map.get($lightThemeProartMainPalette, $lightThemeProartMainLightness)};

    --proart-gray-color: #{map.get($lightThemeProartGrayPalette, $lightThemeProartGrayLightness)};
}
