@each $position in ('center', 'stretch', 'space-between', 'start', 'end') {
    .items-#{$position} {
        display: flex;
        align-items: $position;
    }
    
    .content-#{$position} {
        display: flex;
        justify-content: $position;
    }
}
