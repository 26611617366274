.input {
    height: 2.5rem;
    border-radius: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    
    &.search {
        border: 1px solid $whiteColor;
        background: transparent;
        color: $whiteColor;
    }

    &:focus {
        outline: 1px solid $whiteColor;
    }
}
