.ui-datepicker-input {
    max-width: 180px;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    background-color: $proartGrayColor;
    color: $proartMainFontColor;
    border-radius: 5px;
    appearance: none;
    box-shadow: none;
    background-image: none;
    outline: none;
    padding-left: 20px;
    border: 0;

    &:focus {
        outline: none;
    }
}

.ui-datepicker {
    width: 280px;
    padding: 5px;
    background: $backgroundColorAlternate;
    border-radius: 4px;
    box-shadow: 0 4px 40px -8px rgba(black, 0.2);
    opacity: 0;

    &.active {
        opacity: 1;
    }

    .ui-datepicker-header {
        height: 40px;
        padding: 3px;
        margin-bottom: 10px;

        .ui-datepicker-title {
            text-align: center;
            line-height: 34px;
        }
    }

    .ui-datepicker-month,
    .ui-datepicker-year {
        appearance: none;
        border: 0;
        background: none;
        outline: none;
        font-size: 14px;
        font-weight: 600;
        color: $proartMainFontColor;
        margin: 0 1px;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        position: relative;
        display: inline-block;
        width: 34px;
        height: 34px;
        cursor: pointer;
        text-indent: 9999px;
        overflow: hidden;
        border-radius: 3px;

        &:hover {
            background: $grayColor;
        }
    }

    .ui-datepicker-prev {
        float: left;

        &:after {
            transform: rotate(45deg);
            margin-left: 15px;
        }
    }

    .ui-datepicker-next {
        float: right;

        &:after {
            transform: rotate(-135deg);
            margin-left: 13px;
        }
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        &:after {
            content: '';
            position: absolute;
            display: block;
            margin-top: -10px;
            width: 6px;
            height: 6px;
            border-left: 2px solid $proartMainFontColor;
            border-bottom: 2px solid $proartMainFontColor;
            pointer-events: none;
        }
    }

    .ui-datepicker-calendar {
        width: 100%;
        text-align: center;

        thead {
            tr {
                th {
                    width: 40px;
                    padding-bottom: 6px;

                    span {
                        display: block;
                        width: 100%;
                        padding: 0;
                        color: $grayColor;
                        font-size: 10px;
                        font-weight: 700;
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding-right: 3px;
                    padding-bottom: 3px;

                    &:first-child {
                        padding-left: 3px;
                    }
                }
            }
        }
    }

    .ui-state-default {
        display: block;
        text-decoration: none;
        color: $proartMainFontColor;
        height: 34px;
        line-height: 34px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 600;

        &:hover {
            background: $grayColor;
            text-decoration: none;
            color: $proartOrangeColor;
        }
    }

    .ui-state-highlight {
        color: $proartSecondColor;
    }

    .ui-state-active:not(.ui-state-highlight) {
        color: $proartSecondColor;
        background: $proartMainBackgroundColor;
    }

    .ui-datepicker-unselectable {
        .ui-state-default {
            color: $grayColor;
            pointer-events: none;
        }
    }
}
