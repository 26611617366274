@use 'sass:map';

$darkThemeBackgroundColor: map.get($grayColors, 10);
$darkThemeBackgroundColorAlternate: map.get($grayColors, 5);
$darkThemeBackgroundColorSolid: map.get($grayColors, 0);
$darkThemeBackgroundColorGray: map.get($grayColors, 20);
$darkThemeBackgroundColorGray40: map.get($grayColors, 40);
$darkThemeBackgroundColorGray60: map.get($grayColors, 60);
$darkThemeFontColor: map.get($grayColors, 100);

$darkThemeNormalLightness: 600;
$darkThemeAccentLightness: $darkThemeNormalLightness - 200;
$darkThemeBackgroundLightness: 900;
$darkThemeProartMainBackgroundLightness: 800;
$darkThemeProartMainFontLightness: 0;
$darkThemeProartGrayLightness: 800;

$darkThemeMainPalette: $blueColors;
$darkThemeErrorPalette: $redColors;
$darkThemeWarningPalette: $yellowColors;
$darkThemeSuccessPalette: $greenColors;
$darkThemeProartMainPalette: $proartMainColors;
$darkThemeProartGrayPalette: $proartGrayColors;

body.dark-theme {
    --background-color: #{$darkThemeBackgroundColor};
    --background-color-alternate: #{$darkThemeBackgroundColorAlternate};
    --background-color-solid: #{$darkThemeBackgroundColorSolid};
    --background-color-solid-alternate: #{$darkThemeBackgroundColorGray40};
    --background-color-solid-section-header: #{$darkThemeBackgroundColorGray60};
    --background-color-gray: #{$darkThemeBackgroundColorGray};
    --font-color: #{$darkThemeFontColor};

    --main-color: #{map.get($darkThemeMainPalette, $darkThemeNormalLightness)};
    --main-color-accent: #{map.get($darkThemeMainPalette, $darkThemeAccentLightness)};
    --main-color-background: #{map.get($darkThemeMainPalette, $darkThemeBackgroundLightness)};

    --error-color: #{map.get($darkThemeErrorPalette, $darkThemeNormalLightness)};
    --error-color-accent: #{map.get($darkThemeErrorPalette, $darkThemeAccentLightness)};
    --error-color-background: #{map.get($darkThemeErrorPalette, $darkThemeBackgroundLightness)};

    --warning-color: #{map.get($darkThemeWarningPalette, $darkThemeNormalLightness)};
    --warning-color-accent: #{map.get($darkThemeWarningPalette, $darkThemeAccentLightness)};
    --warning-color-background: #{map.get($darkThemeWarningPalette, $darkThemeBackgroundLightness)};

    --success-color: #{map.get($darkThemeSuccessPalette, $darkThemeNormalLightness)};
    --success-color-accent: #{map.get($darkThemeSuccessPalette, $darkThemeAccentLightness)};
    --success-color-background: #{map.get($darkThemeSuccessPalette, $darkThemeBackgroundLightness)};

    --proart-main-font-color: #{map.get($darkThemeProartMainPalette, $darkThemeProartMainFontLightness)};
    --proart-main-color-background: #{map.get($darkThemeProartMainPalette, $darkThemeProartMainBackgroundLightness)};

    --proart-gray-color: #{map.get($darkThemeProartGrayPalette, $darkThemeProartGrayLightness)};
}
