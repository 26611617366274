.latest-vod {
    background-color: $whiteColor;
}

h2.latest-vod__title {
    margin: 0;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
    background-color: $backgroundColorSolidSectionHeader;
    color: $proartMainFontColor;
}

.latest-vod__items {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 45px;
    justify-content: center;

    & > * {
        width: 100%;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;

        @include rwd(laptop) {
            max-width: unset;
        }
    }

    @include rwd(laptop) {
        flex-direction: row;

        & > * {
            width: 33%;
        }
    }
}

.latest-vod__item {
    background-color: $proartSecondColor;

    &:hover {
        color: $whiteColor;
    }
}

.latest-vod__item-figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    display: block;

    .skeleton,
    img:not(.latest-vod__series-thumbnail) {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        width: 100%;
    }

    .latest-vod__series-thumbnail {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}

.latest-vod__item-title {
    padding: 0 1rem 1rem;
    color: $whiteColor;
    text-align: center;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        color: $whiteColor;
    }
}

.latest-vod__content {
    color: $whiteColor;
    background-color: $proartMainBackgroundColor;
    padding: 20px;

    @include rwd(laptop) {
        padding: 40px 30px;
    }
}

.latest-vod__cta {
    text-align: center;
    margin: 0 1rem;
}
