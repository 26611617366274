.hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: -5px 0;
    padding: 0px;
    justify-content: center;

    span {
        background: $whiteColor;
        border-radius: 10px;
        height: 4px;
        margin: 4px 0;
        transition:
            transform 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6),
            width 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        will-change: transform, width;
        width: 100%;

        &:nth-of-type(1) {
            transform-origin: bottom;
        }

        &:nth-of-type(2) {
            transform-origin: top;
        }

        &:nth-of-type(3) {
            transform-origin: bottom;
        }
    }

    &.active {
        span {
            &:nth-of-type(1) {
                transform: rotate(45deg) translate(7px, 8px);
            }
    
            &:nth-of-type(2) {
                transform: rotate(-45deg) translate(0px, 0px);
            }
    
            &:nth-of-type(3) {
                width: 50%;
                transform: translate(16px, -6px) rotateZ(45deg);
            }
        }
    }
}
