.ui-theme-changer {
    $lightBackground: #4b5b73;
    $darkBackground: #4b5b73;
    $buttonColor: #ededed;
    $sunColor: #f6f6f6;
    $moonColor: #f6f6f6;

    .switch {
        font-size: 12px;
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $lightBackground;
            transition: 0.4s;
            border-radius: 21px;

            &:before {
                position: absolute;
                content: '';
                height: 18px;
                width: 18px;
                border-radius: 18px;
                left: 5px;
                bottom: 3px;
                z-index: 2;
                background-color: $buttonColor;
                transition: 0.4s;
            }
        }

        .sun {
            svg {
                position: absolute;
                top: 2px;
                left: 26px;
                z-index: 1;
                width: 19px;
                height: 19px;
                animation: rotate 15s linear infinite;

                polygon {
                    fill: $sunColor;
                }
            }
        }

        .moon svg {
            fill: $lightBackground;
            position: absolute;
            top: 4px;
            left: 6px;
            z-index: 1;
            width: 17px;
            height: 17px;
            animation: tilt 5s linear infinite;

            path {
                fill: $moonColor;
            }
        }

        .input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: $darkBackground;
            }

            &:focus + .slider {
                box-shadow: 0 0 1px $darkBackground;
            }

            &:checked + .slider:before {
                transform: translateX(22px);
            }
        }
    }

    @keyframes rotate {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes tilt {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(-10deg);
        }

        75% {
            transform: rotate(10deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }
}
