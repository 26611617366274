.program-tv-days {
    background-color: $proartGrayColor;

    .container {
        padding: 20px;

        @include rwd(desktop) {
            padding: 40px;
        }

        .days {
            display: flex;
            justify-content: center;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 20px;

            @include rwd(tablet) {
                gap: 20px;
            }

            @include rwd(desktop) {
                justify-content: space-between;
                flex-wrap: nowrap;
                margin-bottom: 40px;
            }

            .day {
                background-color: $proartSecondColor;
                padding: 8px;
                width: 100%;
                cursor: pointer;

                @include rwd(tablet) {
                    width: 200px;
                    padding: 14px 10px;
                }

                &.active {
                    background-color: $proartMainBackgroundColor;
                }

                h3,
                h4 {
                    color: $whiteColor;
                }

                h3 {
                    font-size: $fontSizeL;
                    font-weight: 500;
                    text-transform: uppercase;
                    margin: 0;
                }

                h4 {
                    font-size: $fontSizeM;
                    font-weight: 400;
                    text-align: center;
                }
            }
        }

        .program-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-height: 1000px;
            overflow-y: auto;

            .program {
                display: flex;
                min-height: 110px;
                background-color: $backgroundColorSolid;
                text-decoration: none;

                .time {
                    padding: 10px 10px;
                    color: $proartMainFontColor;
                    font-weight: 500;
                    font-size: $fontSizeL;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    min-width: 70px;

                    @include rwd(tablet) {
                        width: 90px;
                    }

                    @include rwd(laptop) {
                        padding: 10px 20px;
                        width: 110px;
                        font-size: $fontSizeXL;
                    }

                    &.current {
                        color: $whiteColor;
                        background-color: $proartSecondColor;
                    }

                    .ongoing {
                        text-transform: uppercase;
                    }
                }

                .content {
                    width: 100%;
                    padding: 10px;

                    @include rwd(laptop) {
                        padding: 10px 20px;
                    }

                    h3 {
                        color: $proartMainFontColor;
                        text-transform: uppercase;
                        text-align: left;
                        margin-bottom: 1px;
                        letter-spacing: 0;
                        font-weight: 700;
                        font-size: $fontSizeL;

                        @include rwd(laptop) {
                            font-size: $fontSizeXL;
                        }
                    }

                    p {
                        color: $proartMainFontColor;
                        font-size: $fontSizeM;
                        margin: 0;

                        @include rwd(laptop) {
                            font-size: $fontSizeL;
                        }
                    }

                    .status {
                        font-size: $fontSizeXS;
                        text-transform: uppercase;
                        padding: 0 3px;
                        text-align: center;
                        color: $whiteColor;
                        width: 100px;
                        border-radius: 10px;
                        margin-top: 14px;

                        &.live {
                            background-color: $proartSecondColor;
                        }

                        &.repeat {
                            background-color: $proartOrangeColor;
                        }

                        &.premier {
                            background-color: $successColor;
                        }
                    }
                }
            }
        }
    }
}
