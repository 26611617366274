@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton {
    border-radius: 5px;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-square {
    width: 100%;
    height: 0;
    padding-top: 100%;
    display: block;
}

.skeleton-ratio-4-3 {
    width: 100%;
    height: 0;
    padding-top: 75%;
    display: block;
}

.skeleton-pharagraph {
    height: 15px;
    width: 100%;
    margin-bottom: 10px;

    &.sm {
        height: 10px;
        margin-bottom: 8px;
    }

    &.xl {
        height: 30px;
        margin-bottom: 15px;
    }
}

.skeleton-50-width {
    display: block;
    width: 50%;
    height: 15px;
    margin-bottom: 10px;

    &.sm {
        height: 10px;
        margin-bottom: 8px;
    }

    &.xl {
        height: 36px;
        margin-bottom: 18px;
    }

    &.right {
        margin-left: auto;
    }
}

.skeleton__search-movie {
    display: flex;
    gap: 10px;
    align-items: flex-start;

    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }

    .skeleton-pharagraph {
        &:first-of-type {
            width: 45%;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
