.cookie-popup {
    display: none;
    position: fixed;
    z-index: 9;
    left: 1rem;
    bottom: 1rem;
    max-width: calc(100% - 2rem);
    width: 500px;
    background-color: $proartGrayColor;
    border: 1px solid $proartMainBackgroundColor;
    padding: 1rem;
    border-radius: 20px;

    &.open {
        display: block;
    }
}

.cookie-popup__cta {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    font-size: $fontSizeS;

    .button.orange {
        padding: 0.5rem 2rem;
    }
}

.cookie-popup__title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: $fontSizeM;
    font-weight: 700;
}

.cookie-popup__description {
    margin: 0;
    font-size: $fontSizeS;
}

@include darkTheme() {
    .cookie-popup__link {
        color: $proartOrangeColor;
    }
}
