.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    @include rwd (desktop) {
        flex-wrap: nowrap;
    }
    
    &.vertical {
        flex-direction: column;
    }

    @for $col from 1 through 12 {
        .col-#{$col} {
            width: 100%;
            flex: 0 0 100%;
            
            @include rwd (desktop) {
                $width: calc(100 / 12) * $col * 1%;
                width: $width;
                flex: 0 0 $width;
            }
        }
    }
}
