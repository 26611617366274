h1 {
    font-weight: 900;
    font-size: $fontSizeTitle;
    line-height: $fontSizeTitle;

    @include rwd(desktop) {
        font-size: $fontSizeTitleDesktop;
        line-height: $fontSizeTitleDesktop;
    }
}

a {
    text-decoration: none;
}

.text-lead {
    font-size: $fontSizeXXL;
    line-height: $fontSizeXXL * 1.1;

    @include rwd(desktop) {
        font-size: $fontSizeXXXL;
        line-height: $fontSizeXXXL * 1.1;
    }
}


@each $align in ('center', 'start', 'end') {
    .text-#{$align} {
        text-align: $align;
    }
}

.text-title {
    margin: 0;
    font-size: $fontSizeL;
    text-align: center;
    font-weight: 600;
    letter-spacing: 5px;
    text-transform: uppercase;

    @include rwd (desktop) {
        font-size: $fontSizeXL;
    }
}
