@use 'sass:map';

$breakpoints: (
    tablet: 500px,
    laptop: 1025px,
    desktop: 1280px,
    tv: 2000px
);

@mixin rwd($breakpointMin, $breakpointMax: false) {
    @if ($breakpointMax) {
        @media all and (min-width: map.get($breakpoints, $breakpointMin)) and (max-width: (map.get($breakpoints, $breakpointMax) - 1)) {
            @content;
        }
    } @else {
        @media all and (min-width: map.get($breakpoints, $breakpointMin)) {
            @content;
        }
    }
}
