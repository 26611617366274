.ui-sort-select {
    position: relative;
    display: flex;
    max-width: 180px;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    overflow: hidden;
    color: $proartMainFontColor;

    select {
        appearance: none;
        border: 0;
        box-shadow: none;
        padding-left: 20px;
        flex: 1;
        color: $proartMainFontColor;
        background-image: none;
        background-color: $proartGrayColor;
        cursor: pointer;

        &::-ms-expand {
            display: none;
        }

        &:focus-visible {
            outline: 0;
        }

        option {
            padding: 5px;
            border-bottom: 1px solid $proartMainBackgroundColor;
        }
    }

    &::after {
        content: '\25BC';
        position: absolute;
        top: 0;
        right: 6%;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        color: $proartMainFontColor;
        pointer-events: none;
    }
}
