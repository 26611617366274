.material-videos-section {
    $menuHeight: 100px;
    display: flex;
    gap: 20px;
    padding-top: $menuHeight + 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding: calc($menuHeight + 20px) 10px 20px;

    .ui-pagination {
        width: 100%;
    }

    .filters-box {
        width: 100%;
        
        @include rwd(tablet) {
            flex-basis: 180px;
        }

        @include rwd(laptop) {
            flex-basis: 220px;
        }

        .reset-filters {
            font-size: $fontSizeS;
            margin: 1rem 1rem 0 1rem;
            display: inline-block;
        }
    }

    .filters {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
        flex-basis: 100%;

        @include rwd(tablet) {
            justify-content: flex-start;
            flex-direction: column;
        }

        .ui-select-box {
            flex: 1;
            width: 180px;

            @include rwd(tablet) {
                flex: initial;
            }
        }

        input,
        .ui-sort-select {
            flex: 1;
            min-width: 140px;

            @include rwd(tablet) {
                flex: initial;
            }
        }

        h4 {
            color: $proartMainFontColor;
            text-align: left;
            width: 100%;
            padding-left: 20px;
        }
    }

    .materials {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        max-width: 900px;

        .material {
            display: flex;
            gap: 10px;
            align-items: flex-start;
            text-decoration: none;
            color: $proartMainFontColor;
            background-color: $backgroundColorSolid;
            padding: 16px;
            border-radius: 20px;
            width: 100%;
            flex-direction: column;

            @include rwd(tablet) {
                max-width: 900px;
                flex-direction: row;
            }

            @include rwd(laptop) {
                gap: 20px;
            }

            &:hover {
                background-color: $proartGrayColor;
            }

            &-image {
                width: 100%;
                flex-shrink: 0;
                flex-grow: 1;

                @include rwd(tablet) {
                    width: 50%;
                    max-width: 260px;
                }

                @include rwd(laptop) {
                    width: 100%;
                    max-width: 360px;
                }
            }

            figure {
                width: 100%;
                height: 0;
                padding-top: 56.25%;
                border-radius: 20px;
                overflow: hidden;
                position: relative;

                img {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .content {
                h3 {
                    text-align: left;
                    font-size: $fontSizeL;
                    margin-bottom: 5px;
                    color: $proartMainFontColor;
                }

                .publishedAt {
                    color: $grayColor;
                    font-size: $fontSizeM;
                }

                p {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: $fontSizeM;
                    line-height: $fontSizeM;
                    color: $grayColor;
                }
            }
        }
    }
}
