@import './config/import';
@import './theme/theme';
@import './ui/import';
@import './components/import';

@mixin headerStyles($size) {
    text-align: center;
    letter-spacing: 1px;
    font-size: $size;
    margin-bottom: $size;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    font-size: $fontSizeM;
    line-height: $fontSizeL;

    @include rwd(laptop) {
        font-size: $fontSizeL;
        line-height: $fontSizeXL;
    }
}

body {
    background-color: $backgroundColorSolid;
    font-family: $fontFamily;
    color: $fontColor;
    min-height: 100vh;
    min-width: 320px;
    position: relative;

    h2 {
        @include headerStyles($fontSizeXL);
    }

    h3 {
        @include headerStyles($fontSizeL);
    }

    p {
        margin-bottom: 1rem;
    }

    a {
        color: $mainColor;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: $mainColorAccent;
            text-decoration: underline;
        }
    }

    .hidden {
        display: none !important;
    }

    .clear {
        clear: both;
    }

    input:-webkit-autofill {
        background-clip: text;
        -webkit-text-fill-color: $fontColor;
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    .ad-container {
        min-height: 280px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
    }
}

html {
    scroll-behavior: smooth;
}
