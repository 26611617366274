.material-proposed-video {
    .material-thumbnail {
        display: grid;
        grid-template-columns: calc(40% - 10px) 60%;
        grid-gap: 10px;
        color: $proartMainFontColor;
        padding: .5rem 0;
        border-radius: 10px;

        @include rwd(laptop) {
            padding: .5rem;
        }

        &:hover {
            text-decoration: none;
            background-color: $proartGrayColor;
        }

        figure {
            width: 100%;
            height: 0;
            padding-top: 56.25%;
            position: relative;
            overflow: hidden;
            border-radius: inherit;
        }
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: contain;
            object-position: top;
        }

        .content {
            padding: 6px;

            h4 {
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 3px;
                font-weight: 500;
                font-size: $fontSizeL;
                line-height: $fontSizeL;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                min-width: 0;
                width: 100%;
            }

            p {
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $fontSizeS;
                line-height: $fontSizeS;
                margin: 0;
            }
        }
    }
}
