.see-the-latest-premiers {
    .section-header {
        background-color: $backgroundColorSolidSectionHeader;
        color: $proartMainFontColor;
    }

    .button {
        color: $whiteColor;
        background-color: $proartSecondColor;
        padding: 0.7rem 3rem;
        text-transform: uppercase;
    }

    .container {
        background-color: $proartGrayColor;
        padding: 20px;

        @include rwd(laptop) {
            padding: 40px 30px;
        }

        .promoted-blocks-container {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            flex-direction: column;
            align-items: center;
            padding-bottom: 20px;

            @include rwd(laptop) {
                align-items: flex-start;
                flex-direction: row-reverse;
                padding-bottom: 40px;
            }

            @include rwd(desktop) {
                gap: 20px;
            }

            .promoted-block {
                min-width: 300px;
                width: 100%;
                max-width: 500px;

                figure {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-top: 56.25%;
                    overflow: hidden;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                    display: block;
                }

                .content {
                    background-color: $proartMainBackgroundColor;
                    padding: 12px 10px;
                    color: $whiteColor;
                    height: 106px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    .title {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        h3 {
                            text-align: center;
                            padding-bottom: 6px;
                            margin-bottom: 0;
                            font-size: $fontSizeXL;
                            line-height: $fontSizeXL + 2px;
                            font-weight: 600;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            min-width: 0;
                            width: 100%;
                        }
                    }

                    p {
                        text-align: center;
                        font-size: $fontSizeL;
                        line-height: $fontSizeXL;
                        margin: 0;
                    }
                }
            }
        }
    }
}
