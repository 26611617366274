.hero-banner-slider {
    background-color: $proartMainBackgroundColor;
    background-size: cover;
    background-position: right bottom;
    background-repeat: no-repeat;
    padding-top: 225px;

    @include rwd(laptop) {
        aspect-ratio: 16 / 9;
    }

    .content-wrapper {
        @include rwd(desktop) {
            padding-left: 120px;
            padding-right: 120px;
        }
    }
}

.swiper.hero-banner-slider__swiper {
    color: $whiteColor;
    padding-left: 1rem;
    padding-right: 1rem;

    h1,
    h2 {
        margin-top: 0;
        margin-bottom: 1rem;
    }
}

.hero-banner-slider__content {
    width: 100%;
    margin-bottom: 60px;

    @include rwd(desktop) {
        margin-bottom: 120px;
    }

    .text-lead {
        margin: 0;
        text-align: start;
    }
}

.hero-banner-slider__cta {
    margin-bottom: 40px;
    width: 100%;

    p {
        margin-top: 0;
    }

    .content-end {
        justify-content: start;
        margin-top: 3rem;

        @include rwd(desktop) {
            justify-content: end;
            margin-top: 0;
        }
    }
}

.hero-banner-slider__partner {
    height: 45px;
    display: inline-block;

    &:not(:last-of-type) {
        margin-right: 50px;
    }

    &.promax {
        height: 40px;
        margin-bottom: 5px;
    }

    img {
        height: inherit;
        width: auto;
    }
}
