.contact__section {
    &:nth-of-type(even) {
        .section-header {
            background-color: $proartSecondColor;
        }
    }

    .content-wrapper {
        padding: 75px 20px 0 20px;
    }

    .contact__section-grid:not(.single) {
        &:first-of-type,
        &:nth-of-type(3n + 1) {
            background-color: $proartGrayColor;
        }

        &:nth-of-type(3n + 2) {
            background-color: $backgroundColorSolidAlternate;

            @include rwd(tablet) {
                .contact__item-picture {
                    order: -1;
                }
            }
        }

        &:nth-of-type(3n) {
            background-color: $proartSecondColor;
            color: $whiteColor;
        }
    }

    &-grid {
        display: grid;
        justify-content: center;
        grid-template-columns: 100%;
        grid-gap: 50px;
        color: $proartMainFontColor;

        &.single {
            .contact__item {
                &-caption {
                    h2 {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }

        @include rwd(laptop) {
            grid-template-columns: calc(50% - 50px) calc(50% - 50px);
            grid-gap: 100px;

            &.single {
                grid-template-columns: 65%;
                grid-gap: 0px;

                .contact__section-grid-item {
                    grid-template-columns: 60% 40%;
                    gap: 1rem;
                }

                .contact__item-caption {
                    margin-top: 100px;
                    height: calc(100% - 100px);
                }
            }
        }
    }

    &-grid-item {
        display: grid;
        grid-template-columns: 100%;

        @include rwd(tablet) {
            grid-template-columns: 50% 50%;
        }
    }
}

.contact__item {
    &-caption {
        padding-bottom: 20px;
        margin-bottom: 20px;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;

        h2 {
            text-transform: uppercase;
            margin-bottom: 40px;
            font-size: $fontSizeXL;
            line-height: $fontSizeXL;

            @include rwd(tablet) {
                text-align: start;
                font-size: $fontSizeXXL;
                line-height: $fontSizeXXL;
            }

            @include rwd(laptop) {
                margin-bottom: 70px;
                font-size: $fontSizeXXXL;
                line-height: $fontSizeXXXL;
            }
        }

        div {
            font-weight: 300;
            font-size: $fontSizeM;
            line-height: $fontSizeM;
            text-align: justify;

            @include rwd(laptop) {
                font-size: $fontSizeL;
                line-height: $fontSizeXL;
            }

            &.contact__item-description {
                margin-bottom: 8px;
                text-align: left;
            }
        }
    }

    &-button {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        gap: 10px;

        @include rwd(laptop) {
            order: unset;
        }

        .button {
            font-size: $fontSizeM;
            text-align: left;
            padding: 0.5rem 1rem;
            text-transform: none;
            width: 100%;

            @include rwd(tablet) {
                width: fit-content;
            }
        }
    }

    &-picture {
        order: -1;

        @include rwd(laptop) {
            order: unset;
        }

        img {
            max-width: 290px;
            margin-left: auto;
            margin-right: auto;
            object-fit: contain;
            object-position: bottom;
            width: 100%;
            height: 100%;
            display: block;

            @include rwd(tablet) {
                max-width: unset;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
