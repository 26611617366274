@use 'sass:map';

.navbar {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    background-color: $navbarBackgroundColorMobile;

    &::before {
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    &.scrolled,
    &.active {
        background-color: $navbarBackgroundColorMobile;
    }

    @include rwd(desktop) {
        background-color: $navbarBackgroundColor;

        &.scrolled,
        &.active {
            background-color: $navbarBackgroundColor;
        }
    }
}

.navbar-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.navbar-hamburger {
    background: transparent;
    padding: 0;
    border: unset;
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    margin-left: 1rem;
    order: 2;
    position: relative;
    box-sizing: content-box;

    @include rwd(desktop) {
        margin-left: auto;
        display: none;
    }
}

.navbar-brand {
    height: 2rem;

    img {
        height: 40px;
        width: auto;
    }
}

.ui-theme-changer {
    display: none;
    order: 3;

    @include rwd(tablet) {
        margin-left: 1.5rem;
    }

    @include rwd(desktop) {
        margin-left: unset;
        order: unset;
    }
}

.navbar-menu__box {
    gap: 0;
    display: none;
    order: 4;
    width: 100%;

    &.active {
        width: 100%;
        display: flex;
        flex-direction: column;

        .navbar-menu,
        .navbar-search {
            display: flex;
        }
    }

    @include rwd(desktop) {
        margin-left: auto;
        display: flex;
        align-items: center;
        order: unset;
        width: auto;

        &.active {
            display: flex;
            width: auto;
            flex-direction: row;

            .navbar-menu {
                display: block;
            }

            .navbar-search {
                display: flex;
            }
        }
    }
}

.navbar-menu {
    list-style: none;
    padding: 2rem 0 0 0;
    margin: 0;
    display: none;
    width: 100%;
    flex-direction: column;

    @include rwd(desktop) {
        margin-left: auto;
        display: block;
        padding: 0;
    }
}

.navbar-menu__item {
    display: inline;
    padding: 0.75rem 0;
    position: relative;

    @include rwd(desktop) {
        margin-right: 45px;
        padding: 0;

        &::after {
            content: '';
            display: block;
            height: 33px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -33px;
        }

        &:hover {
            .navbar-dropdown {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    .navbar-dropdown {
        display: none;

        @include rwd(desktop) {
            display: block;
            height: fit-content;
            position: absolute;
            list-style: none;
            margin-top: 33px;
            will-change: transform;
            transform: translateX(-50%);
            left: 50%;
            border-radius: 0 0 10px 10px;
            font-size: $fontSizeL;
            overflow: hidden;
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
            pointer-events: none;

            &::before {
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);
                content: '';
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
            }

            &-item {
                text-align: center;
                width: 100%;
                position: relative;
                background-color: $navbarBackgroundColor;

                a {
                    padding: 0.25rem 0.75rem;
                    display: block;
                    width: 100%;
                    color: $whiteColor;
                    text-decoration: none;

                    &:hover {
                        background-color: $navbarBackgroundColor;
                    }

                    &:first-of-type {
                        padding: 0.5rem 0.75rem 0.25rem;
                    }

                    &:last-of-type {
                        padding: 0.25rem 0.75rem 0.5rem;
                    }
                }
            }
        }
    }
}

.navbar-menu__link {
    color: $whiteColor;
    text-decoration: none;
    font-size: $fontSizeXL;
    display: inline-block;

    &:hover {
        color: $whiteColor;
    }

    @include rwd(desktop) {
        font-size: $fontSizeL;
    }
}

.navbar-search {
    align-items: stretch;
    gap: 1rem;
    display: none;

    &.active {
        display: flex;
        width: 100%;
        order: 5;
        margin-top: 1rem;
        justify-content: center;

        .input {
            width: 100%;

            @include rwd(laptop) {
                width: 480px;
            }

            @include rwd(desktop) {
                width: 240px;
            }
        }

        @include rwd(desktop) {
            margin-top: 0;
            order: unset;
            width: auto;
            justify-content: flex-start;
        }
    }

    .input {
        font-size: $fontSizeS;

        &::placeholder {
            color: $whiteColor;
        }

        @include rwd(desktop) {
            width: 240px;
        }
    }

    .button {
        flex-shrink: 0;
    }

    @include rwd(desktop) {
        margin-right: 45px;
        display: flex;
    }

    &.open {
        .input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .search-results__box {
            display: block;
            opacity: 1;
            z-index: inherit;
            box-shadow: 0 4px 4px $grayColor;
        }
    }
}

.navbar-search__mobile-switcher {
    display: block;
    margin-left: auto;
    width: 1.75rem;
    height: 1.75rem;
    background-color: transparent;
    border: unset;
    color: $whiteColor;
    align-self: flex-start;
    margin-top: 0.5rem;

    svg {
        width: inherit;
        height: inherit;
    }

    @include rwd(desktop) {
        display: none;
    }
}

.input-search-zone {
    position: relative;
    width: 100%;

    @include rwd(laptop) {
        width: auto;
    }
}

.search-results__box {
    border: 1px solid $whiteColor;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: $whiteColor;
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    opacity: 0;
    min-width: 100%;
    max-width: 100%;
    max-height: 60vh;
    overflow: hidden;

    @include rwd(laptop) {
        max-height: 400px;
        max-width: 320px;
    }
}

.search-results__list {
    padding: 10px 10px;
    max-height: inherit;
    overflow-y: auto;

    &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 2px;
        background-color: $whiteColor;
    }

    &::-webkit-scrollbar-track:hover {
        background-color: map.get($grayColors, 90);
    }

    &::-webkit-scrollbar-track:active {
        background-color: map.get($grayColors, 90);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $proartOrangeColor;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $proartSecondColor;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: $proartSecondColor;
    }
}

.search-results {
    color: $proartMainBackgroundColor;
    font-size: $fontSizeS;
    padding-left: 13px;
}

.search-result {
    color: $proartMainBackgroundColor;
    font-size: $fontSizeM;
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
    align-items: flex-start;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.search-result__title {
    margin: 0 0 5px 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-result__description {
    margin: 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1rem;
    max-height: 2rem;
}

.search-result__text {
    width: calc(100% - 80px - 10px);
}

.search-result__figure {
    margin: 0;
    flex-shrink: 0;
    overflow: hidden;
    display: block;

    img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center;
        display: block;
    }
}
